import React from "react";
import Main from "../../../main/Main";
import useStyles from "../../../../components/layout/Styles";
import { Button, Grid, Paper } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProposicaoAcaoPreparatoriaEspecificaHeader from "./ProposicaoAcaoPreparatoriaEspecificaHeader";
import ProposicaoAcaoPreparatoriaEspecificaBotoes from "./ProposicaoAcaoPreparatoriaEspecificaBotoes";
import ProposicaoAcaoPreparatoriaEspecificaInicio from "./ProposicaoAcaoPreparatoriaEspecificaInicio";
import ProposicaoAcaoPreparatoriaEspecificaInformacoes from "./ProposicaoAcaoPreparatoriaEspecificaInformacoes";
import ProposicaoAcaoPreparatoriaEspecificaAnexos from "./ProposicaoAcaoPreparatoriaEspecificaAnexos";
import ProposicaoAcaoPreparatoriaEspecificaCarrouselTabs from "./ProposicaoAcaoPreparatoriaEspecificaCarrouselTabs";
import ProposicaoAcaoPreparatoriaService from "../../../../services/ProposicaoAcaoPreparatoriaService";
import ProposicaoAcaoInfluenciaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import CircularLoading from "../../../../components/CircularLoading";
import { useQuery } from '../../../../utils/utils';

export default function ProposicaoAcaoPreparatoriaEspecifica() {
  const { id, id_proposicao } = useParams();
  const [acaoPreparatoria, setAcaoPreparatoria] =
    React.useState<IAcaoPreparatoria>();
  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(false);
  const classes = useStyles();

  let comp = useQuery().get('comp');


  React.useEffect(() => {
    loadAcaoPreparatoria();

    // eslint-disable-next-line
  }, [id]);



  const loadAcaoPreparatoria = () => {
    if (id) {
      ProposicaoAcaoPreparatoriaService.Get(Number(id)).then(
        (acao_preparatoria) => {
          ProposicaoAcaoInfluenciaNotaTecnicaService.GetList(
            Number(id),
            0,
            null
          ).then((notaTecnica) => {
            setAcaoPreparatoria(() => {
              return {
                ...acao_preparatoria?.data,
                notaTecnica: notaTecnica ? notaTecnica : [],
              };
            });
            setLoading2(true);
          });
          setTimeout(() => {
            setLoading(false)
          }, 1000)

        }
      );
    }
  };

  const handleOnChange = (data) => {
    setAcaoPreparatoria((oldValue) => {
      return { ...oldValue, ...data };
    });
  };

  return (
    <div className={classes.root}>
      <Main>
        <>
          <Grid container justifyContent="space-around">
            <Paper style={{ borderRadius: "7px", width: "100%" }}>
              {loading ? (
                <CircularLoading
                  size={100}
                  top={isMobile ? "37%" : "40%"}
                  left={isMobile ? "6%" : "16%"}
                  zIndex={2}
                />
              ) : (
                <>
                  <ProposicaoAcaoPreparatoriaEspecificaHeader />
                  <ProposicaoAcaoPreparatoriaEspecificaBotoes
                    acao_preparatoria={acaoPreparatoria}
                    reload={() => loadAcaoPreparatoria()}
                  />
                  <ProposicaoAcaoPreparatoriaEspecificaInicio
                    acao_preparatoria={acaoPreparatoria}
                    reload={() => loadAcaoPreparatoria()}
                  />
                  <ProposicaoAcaoPreparatoriaEspecificaInformacoes
                    acao_preparatoria={acaoPreparatoria}
                  />
                  <ProposicaoAcaoPreparatoriaEspecificaAnexos
                    acao_preparatoria={acaoPreparatoria}
                  />
                </>
              )}
            </Paper>
            {loading2 ?
              <Grid style={{ marginTop: "23px", width: "100%" }}>
                <ProposicaoAcaoPreparatoriaEspecificaCarrouselTabs
                  acao_preparatoria={acaoPreparatoria}
                  reload={() => {
                    loadAcaoPreparatoria();
                  }}
                  onChange={handleOnChange}
                  comp={Number(comp)}
                />
              </Grid>
              :
              null
            }
          </Grid>
        </>
      </Main>
    </div>
  );
}
