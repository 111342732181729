import * as React from "react";

import {
  Grid,
  Link,
  TextField,
  Typography,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  IconButton,
  Icon,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  FormControl,
  makeStyles,
  DialogContent,
} from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { useAlert } from "../../../../contexts/AlertContext";
import useStyles from "../../../../components/layout/Styles";
import ParlamentarDemandaService from "../../../../services/ParlamentarDemandaService";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import { CalendarToday } from "@material-ui/icons";
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import InputTextField from "../../../../components/InputTextField";
import {
  ICheckBoxNestedItem,
  InputDropDownCheckBoxNested,
} from "../../../../components/CheckBoxNested";
import SolidButton from "../../../../components/SolidButton";
import styled from "styled-components";
import UsuarioService from "../../../../services/UsuarioService";
import { arraysEquals } from "../../../../utils/array.utils";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import { SalvarAnexo } from "../../../../components/Anexos";
import FormGroup from "@mui/material/FormGroup";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ProposicaoAcaoPreparatoriaNotaTecnicaService from "../../../../services/ProposicaoAcaoPreparatoriaNotaTecnicaService";
import ImpactoFinanceiroData from "../../../dashboard/_components/ImpactoFinandeiroData";
import { formatDate } from "../../../../utils/dateUtils";
import ButtonIconLeft from "../../../../components/ButtonIconLeft";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../../components/BootstrapDialogTitle";
import { INotaTecnica } from "../../../../models/Proposicao.Model";
import FeedIcon from "@mui/icons-material/Feed";
import { IAcaoPreparatoria } from "../../../../models/Proposicao.Model";
import { useNavigate } from "react-router-dom";
import CircularLoading from '../../../../components/CircularLoading';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useAuthorization } from '../../../../contexts/AuthorizationContext';
import { TypeAnimation } from 'react-type-animation';
import ProposicaoService from "../../../../services/ProposicaoService";
import jsPDF from 'jspdf';
import { Document, Packer, Paragraph } from 'docx';
import UploadFilesService from "../../../../services/UploadFiles.service";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Quill from "quill";
import { Editor } from "primereact"
import { ICheckBoxNestedItemPrep, InputDropDownCheckBoxNestedAcaoPrep } from "../../../../components/CheckBoxNestedAcaoPrep";
import ReactQuill from 'react-quill';



const DotPosicionamento = styled.div`
  background-color: ${(props) => props.bg};
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: ${isMobile ? "4px" : "25px"};
`;

const GridColumn = styled(Grid)`
  padding: ${isMobile ? "8px 4px 8px 4px" : "15px"};
  overflow-wrap: "break-word";
`;

export default function NotaTecnicaModal(props: {
  onChange: (data: any) => void;
  closeMenu?: () => void;
  reload?: () => void;
  children?: React.ReactNode;
  nota_tecnica: INotaTecnica;
  acao_preparatoria: IAcaoPreparatoria;
  responsaveis: Array<number>;
  editLayout?: boolean;
  isNewStatusAnexo?: () => void;
}) {
  const { pallet } = usePallet();
  const classes = useStyles();
  const { NewAlert } = useAlert();

  const [idReferencia, setIdReferencia] = React.useState<number>();
  const [posicionamento, setPosicionamento] = useState<string>();
  const [prioridade, setPrioridade] = useState<string>();
  const [tipoImpacto, setTipoImpacto] = useState<string>();
  const [impacto, setImpacto] = useState<string>();
  const [titleDialog, setTitleDialog] = useState<string>(
    "QUALIFICAR NOTA TÉCNICA"
  );
  const [outrosMotivos, setOutrosMotivos] = useState<string>();
  const [argumentos, setArgumentos] = useState<string>();
  const [pontosModificacao, setPontosModificacao] = useState<string>();
  const [justificativa1, setJustificativa1] = useState<string>();
  const [justificativaII, setJustificativaII] = useState<string>();
  const [justificativa, setJustificativa] = useState({ field_description: '' });
  const [justificativaTotal, setJustificativaTotal] = useState<number>(0);
  const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItemPrep>>();
  const [userList, setUserList] = useState<Array<number>>([]);
  const [responsavel, setResponsavel] = useState<string>();
  const [confirmacao, setConfirmacao] = useState<boolean>(false);
  const [lockSaveButton, setLockSaveButton] = useState<boolean>(true);
  const [dataRecebimento, setDataRecebimento] = useState(Date);
  const [status, setStatus] = useState<string>();
  const [lockedUserList, setLockedUserList] = useState<Array<number>>([]);
  const [lockedUserListDept, setLockedUserListDept] = useState<Array<number>>([]);
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [comAssist, setComAssist] = React.useState(false);
  const [newUserList, setNewUserList] = useState<Array<number>>([]);
  const [selectedOptions, setSelectedOptions] = useState(['0']);
  const [textoEmenta, setTextoEmenta] = useState('');
  const [resIA, setResIA] = useState('');
  const [loadingResIA, setLoadingResIA] = useState(false);
  const [loadingAnexo, setLoadingAnexo] = React.useState(false);
  const [uri_teor, setUri_teor] = React.useState('');
  const [openPDF, setOpenPDF] = useState(false);


  const handleOpenPDF = () => setOpenPDF(true);
  const handleClosePDF = () => setOpenPDF(false);

  const { user } = useAuthorization();

  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   // Configura o intervalo
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) =>
  //       prevProgress >= 100 ? 10 : prevProgress + 10
  //     );
  //   }, 800);

  //   // Limpa o intervalo ao desmontar o componente
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  useEffect(() => {
    ProposicaoService.Get(Number(props.acao_preparatoria.id_proposicao))
      .then(res => {
        if (res && res.uri_teor) {
          setUri_teor(res?.uri_teor)
        }
      });
  }, [])

  const wrapperRef = React.useRef()

  useEffect(() => {

    new Quill("#just", { theme: "snow" })

  }, [])
  const [departamentoII, setDepartamentoII] = React.useState<Array<any>>([]);

  useEffect(() => {
    SaveValidation();
  }, [
    posicionamento,
    newUserList,
    tipoImpacto,
    justificativa,
    confirmacao,
    prioridade,
  ]);

  function SaveValidation() {
    if (prioridade === "Máxima" && confirmacao === true) {
      setLockSaveButton(false);
    } else if (
      posicionamento &&
      tipoImpacto &&
      justificativa &&
      prioridade != "Máxima"
    ) {
      setLockSaveButton(false);
    } else if (newUserList.length > 0 && justificativa) {
      setLockSaveButton(false);
    } else setLockSaveButton(true);
  }

  useEffect(() => {
    if (open) {
      ProposicaoService.Get(props.acao_preparatoria.id_proposicao).then(async (res) => {
        if (res) {
          setTextoEmenta(res.texto_ementa)
        }
      })
      UsuarioService.GetByDpto().then(async (res) => {
        const comboItens = res.map((departamento) => {
          return {
            id: departamento.id,
            label: departamento.nome,
            items: departamento.usuarios.map((usuario) => {
              return {
                id: usuario.id,
                label: usuario.nome,
                value: false,
              };
            }),
          };
        });
        setDepartamento(comboItens);

        setUserList(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        setLockedUserList(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        // setLockedUserListDept(
        //   props.acao_preparatoria?.responsaveisNotaTecDept.map((item) => {
        //     return Number(item.nota_tecnica_id);
        //   })
        // );
        // setNewUserList

        if (props.editLayout === true) {
          setTitleDialog("EDITAR NOTA TÉCNICA");
        }
      });

      if (props.nota_tecnica.status === "Preenchido") {
        setTipoImpacto(String(props.nota_tecnica.tipo_impacto));
        setPosicionamento(String(props.nota_tecnica.posicionamento));
        setImpacto(String(props.nota_tecnica.valor_impacto));
        setResponsavel(String(props.acao_preparatoria.responsaveis_esforco));
        setPrioridade(String(props.nota_tecnica.prioridade));
        // setJustificativa({ field_description:props.nota_tecnica.justificativa} )
        justificativa.field_description = props.nota_tecnica.justificativa
        setJustificativaII(props.nota_tecnica.justificativa)
        // setJustificativa(
        //   String(
        //     props.nota_tecnica.justificativa
        //       ? props.nota_tecnica.justificativa
        //       : ""
        //   )

        // );
        setResIA(String(
          props.nota_tecnica.texto_ia
            ? props.nota_tecnica.texto_ia
            : ""
        ))
        setJustificativaTotal(props.nota_tecnica.justificativa ? props.nota_tecnica.justificativa.replace(/<\/?[^>]+(>|$)/g, "").length : 0)
        setConfirmacao(Boolean(props.nota_tecnica.aprovado));
        setDataRecebimento(
          formatDate(new Date(props.nota_tecnica.recebido_em), "dd/MM/yyyy")
        );
      }
    }
  }, [open]);

  useEffect(() => {
    setJustificativaTotal(Number(justificativa.field_description?.replace(/(<([^>]+)>)/gi, "")?.length))

  }, [justificativa]);

  const handleSetUserList = React.useCallback(
    (data) => {
      if (!arraysEquals(userList, data.selected)) {
        setDepartamento(() => [...data.list]);
        setUserList(data.selected);
        const deleteFrom = new Set(
          props.acao_preparatoria?.responsaveisNotaTec.map((item) => {
            return Number(item.nota_tecnica_id_responsavel);
          })
        );
        const newValue = data.selected.filter((user) => {
          return !deleteFrom.has(user);
        });
        setNewUserList(newValue);
      }
    },
    [departamento]
  );

  const corPosicionamento = (value: string) => {
    switch (value) {
      case "Contrário":
        return `${pallet.charts.red}`;
      case "Contrário com emenda saneadora":
        return `${pallet.charts.orange}`;
      case "Neutro":
        return `${pallet.charts.purple}`;
      case "Favorável com emenda saneadora":
        return `${pallet.charts.blue}`;
      case "Favorável":
        return `${pallet.charts.green}`;
      case "Pendente":
        return `${pallet.charts.yellow}`;
      case "Indefinido":
        return `${pallet.charts.lightGrey}`;
      case "Favorável ao parecer do relator":
        return '#C71585';
      case "Favorável ao parecer do relator com emenda":
        return '#B8860B';
      case "Contrário ao parecer do relator":
        return '#facb74';
      case "Contrário ao parecer do relator com emenda":
        return '#000000';
      case "Não Respondido":
        return '#D8BFD8';
      case "Não Classificado":
        return '#2F4F4F';
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  function LabelHandlePosicionamento(props: {
    posicionamento: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPosicionamento(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.posicionamento}</Grid>
        </Grid>
      </>
    );
  }

  const corImpacto = (value: string) => {
    switch (value) {
      case "Indiferente":
        return `${pallet.charts.lightGrey}`;
      case "Positivo":
        return `${pallet.charts.green}`;
      case "Negativo":
        return `${pallet.charts.red}`;
      case "Não Mensurável":
        return `${pallet.charts.purple}`;
      default:
        return `${pallet.charts.yellow}`;
    }
  };

  function LabelImpactoHandle(props: {
    impacto: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corImpacto(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.impacto}</Grid>
        </Grid>
      </>
    );
  }

  const corPrioridade = (value: string) => {
    switch (value) {
      case "Máxima":
        return `${pallet.charts.red}`;
      case "Baixa":
        return `${pallet.charts.blue}`;
      case "Média":
        return `${pallet.charts.yellow}`;
      case "Alta":
        return `${pallet.charts.purple}`;
      case "Mínima":
        return `${pallet.charts.green}`;
      default:
        return `${pallet.charts.lightGrey}`;
    }
  };

  function LabelPrioridadeHandle(props: {
    prioridade: string;
    bg: string;
  }): JSX.Element {
    return (
      <>
        <Grid container alignItems="center">
          <Grid item>
            <DotPosicionamento bg={corPrioridade(`${props?.bg}`)} />
          </Grid>
          <Grid item>{props?.prioridade}</Grid>
        </Grid>
      </>
    );
  }

  const posicionamentosItens = [
    {
      key: 0,
      label: (
        <LabelHandlePosicionamento posicionamento={"Neutro"} bg={"Neutro"} />
      ),
      value: "Neutro",
    },
    {
      key: 1,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário com emenda saneadora"}
          bg={"Contrário com emenda saneadora"}
        />
      ),
      value: "Contrário com emenda saneadora",
    },
    {
      key: 2,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário"}
          bg={"Contrário"}
        />
      ),
      value: "Contrário",
    },
    {
      key: 3,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável com emenda saneadora"}
          bg={"Favorável com emenda saneadora"}
        />
      ),
      value: "Favorável com emenda saneadora",
    },
    {
      key: 4,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável"}
          bg={"Favorável"}
        />
      ),
      value: "Favorável",
    },
    {
      key: 5,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável ao parecer do relator"}
          bg={"Favorável ao parecer do relator"}
        />
      ),
      value: "Favorável ao parecer do relator",
    },
    {
      key: 6,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Favorável ao parecer do relator com emenda"}
          bg={"Favorável ao parecer do relator com emenda"}
        />
      ),
      value: "Favorável ao parecer do relator com emenda",
    },
    {
      key: 7,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário ao parecer do relator"}
          bg={"Contrário ao parecer do relator"}
        />
      ),
      value: "Contrário ao parecer do relator",
    },
    {
      key: 9,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Contrário ao parecer do relator com emenda"}
          bg={"Contrário ao parecer do relator com emenda"}
        />
      ),
      value: "Contrário ao parecer do relator com emenda",
    },
    {
      key: 8,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Pendente"}
          bg={"Pendente"}
        />
      ),
      value: "Pendente",
    },

    {
      key: 9,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Não Respondido"}
          bg={"Não Respondido"}
        />
      ),
      value: "Não Respondido",
    },

    {
      key: 10,
      label: (
        <LabelHandlePosicionamento
          posicionamento={"Não Classificado"}
          bg={"Não Classificado"}
        />
      ),
      value: "Não Classificado",
    }
  ];

  const impactoFinanceiroItens = [
    {
      key: 0,
      label: (
        <LabelImpactoHandle impacto={"Não Mensurável"} bg={"Não Mensurável"} />
      ),
      value: "Não Mensurável",
    },
    {
      key: 1,
      label: <LabelImpactoHandle impacto={"Indiferente"} bg={"Indiferente"} />,
      value: "Indiferente",
    },
    {
      key: 2,
      label: <LabelImpactoHandle impacto={"Positivo"} bg={"Positivo"} />,
      value: "Positivo",
    },
    {
      key: 3,
      label: <LabelImpactoHandle impacto={"Negativo"} bg={"Negativo"} />,
      value: "Negativo",
    },
  ];

  const prioridadeItens = [
    {
      key: 0,
      label: <LabelPrioridadeHandle prioridade={"Máxima"} bg={"Máxima"} />,
      value: "Máxima",
    },
    {
      key: 1,
      label: <LabelPrioridadeHandle prioridade={"Baixa"} bg={"Baixa"} />,
      value: "Baixa",
    },
    {
      key: 2,
      label: <LabelPrioridadeHandle prioridade={"Média"} bg={"Média"} />,
      value: "Média",
    },
    {
      key: 3,
      label: <LabelPrioridadeHandle prioridade={"Alta"} bg={"Alta"} />,
      value: "Alta",
    },
    {
      key: 4,
      label: <LabelPrioridadeHandle prioridade={"Mínima"} bg={"Mínima"} />,
      value: "Mínima",
    },
  ];

  const handleImpactoValorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImpacto(event.target.value);
  };

  const handleJustificativaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setJustificativaII(event.target.value);
    setJustificativaTotal(Number(event.target.value.length))
  };

  const handleJustificativaChange2 = (e) => {

    setJustificativa(e.htmlValue)
    setJustificativaTotal(e.htmlValue?.length ? Number(e.htmlValue?.length) : 0)


  }


  const handleSetDeptList = (id: number, valor: boolean) => {
    departamentoII.map((a) => {
      if (a.id === id) {
        a.valor = valor;
      };
  });
  // if()
  // departamentoII.push({id: id,valor:valor})
};

  const renderCustomToolbar = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button className="ql-strike" aria-label="Tracejado"></button>
        <select className="ql-color"></select>
      </span>
    )
  }
  var toolBaroptions = [['bold', 'italic', 'strike', 'underline'],
  [{ color: [] }]]
  const modulos = {
    toolbar: toolBaroptions
  };

  const header = renderCustomToolbar()

  const handleResIAChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setResIA(event.target.value);
  };

  const handleOutrosMotivosChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOutrosMotivos(event.target.value);
  };

  const handleArgumentosChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setArgumentos(event.target.value);
  };

  const handlePontosModificacaoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPontosModificacao(event.target.value);
  };

  const handleJustificativa1Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setJustificativa1(event.target.value);
  };

  const handlePosicionamentoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPosicionamento(event.target.value);
  };

  const handleImpactoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoImpacto(event.target.value);
  };

  const handlePrioridade = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrioridade(event.target.value);
  };

  const handleConfirmacaoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = !!event.target.checked;
    setConfirmacao(value);
  };

  const salvarPosicionamento = () => {
    // setLoading(true)
    const data = {
      impacto: tipoImpacto,
      posicionamento: posicionamento,
      impactoValor: impacto,
      prioridade: prioridade,
      justificativa: justificativa.field_description,
      responsavel: newUserList,
      confirmacao: confirmacao,
      status: "Preenchido",
      id_acao_preparatoria: props.acao_preparatoria.id,
      res_ia: resIA.length > 0 ? resIA : null
    };

    ProposicaoAcaoPreparatoriaNotaTecnicaService.Update(
      data,
      props?.nota_tecnica.id
    )
      .then((res) => {
        // if (res) {
        NewAlert("success", "Nota Técnica salva com sucesso");
        setLoading(false)
        ProposicaoAcaoPreparatoriaNotaTecnicaService.GetList(
          Number(props.acao_preparatoria.id),
          0
        ).then((notaTecnica) => {
          props.onChange({ notaTecnica: notaTecnica ? notaTecnica : [] });
        });
        if (props?.reload) {
          props?.reload();
        }
        if (props?.closeMenu) {
          props?.closeMenu();
        }
        setOpen(false);
        // } else {
        //   NewAlert("error", "Verifique os campos e tente outra vez");
        // }
      })
      .then((data) => {
        // props?.reload();
        //  props?.onChange()
        //  handleAfterUpload()
      });
    setDepartamentoII([])
  };


  const ativarModoAssitente = () => {
    if (comAssist) {
      setComAssist(false)
    } else {
      setComAssist(true)
    }
  }

  const controlSteps = async (sentido) => {
    const maxLevel = 4; // Máximo de níveis
    const maxStep = 6; // Máximo de passos por nível

    const [currentLevel, currentStep] = selectedOptions[0]
      ? selectedOptions[0].split('.').map(Number)
      : [undefined, undefined];

    if (sentido === 'next') {
      if (currentStep < maxStep) {
        setSelectedOptions([]);
        setTimeout(() => {
          setSelectedOptions([`${currentLevel}.${currentStep + 1}`]);
        }, 100);
      } else if (currentLevel < maxLevel) {
        setSelectedOptions([]);
        setTimeout(() => {
          setSelectedOptions([`${currentLevel + 1}.1`]);
        }, 100);
      }
    } else if (sentido === 'back') {
      if (currentStep > 0) {
        setSelectedOptions([]);
        setTimeout(() => {
          setSelectedOptions([`${currentLevel}.${currentStep - 1}`]);
        }, 100);
      } else if (currentLevel > 0 && currentStep !== 0) {
        setSelectedOptions([]);
        setTimeout(() => {
          setSelectedOptions([`${currentLevel - 1}.${maxStep}`]);
        }, 100);
      } else if (currentStep === 0) {
        setSelectedOptions(['0']);
        setTimeout(() => {
          setSelectedOptions(['0']);
        }, 100);
      }

    } else if (currentLevel && sentido === 'next') {
      setSelectedOptions([]);
      setTimeout(() => {
        setSelectedOptions([`${currentLevel}.1`]);
      }, 100);
    }
  };



  const handleChange = (e, cod, value) => {
    if (e.target.checked) {
      setPosicionamento(value);
      setSelectedOptions([cod]); // Apenas um selecionado
    } else {
      setPosicionamento('');
      setSelectedOptions([]);
    }
  };

  const alternativas1 = [
    { texto: 'Achei prejudicial e não vejo como tornar viável', cod: '1.0', value: 'Contrário' },
    { texto: 'A proposição é prejudicial, mas há espaço para melhorá-la, reduzir esses impactos negativos ou deixá-la viável', cod: '2.0', value: 'Contrário com emenda saneadora' },
    { texto: 'Achei a proposição positiva e não é necessário propor ajustes ou mudanças', cod: '3.0', value: 'Favorável' },
    { texto: 'Achei a proposição positiva, mas há espaço para deixa-la ainda melhor', cod: '4.0', value: 'Favorável com emenda saneadora' },
    { texto: 'Essa proposição não traz impactos visíveis para nós', cod: '5.0', value: 'Neutro' },
    { texto: 'Não vi aqui uma opção que me atenda', cod: '6.0', value: 'Pandente' }
  ]

  const gerarPropostaEmenda = async () => {
    setLoadingResIA(true)
    const data = {
      id_nota_tecnica: props.nota_tecnica.id,
      id_acao_preparatoria: props.acao_preparatoria.id,
      id_proposicao: props.acao_preparatoria.id_proposicao,
      posicionamento: posicionamento,
      justificativa1: justificativa1,
      outrosMotivos: outrosMotivos,
      argumentos: argumentos,
      pontosModificacao: pontosModificacao,
      justificativa: justificativa
    }
    await ProposicaoService.GetOpenaiPropostaEmenda(props.nota_tecnica.id, data)
      .then(res => {
        if (res) {
          setResIA(res.response.data)
          setLoadingResIA(false)
        }
      });
  };

  const salvarAnexoIA = async () => {
    setLoadingAnexo(true);

    const formattedTextResIA = resIA.replace(/\s+/g, ' ').replace(/(.{1,80})(?=\s|$)/g, '$1\n');
    const formattedTextTextoEmenta = textoEmenta.replace(/\s+/g, ' ').replace(/(.{1,80})(\s|$)/g, '$1\n');

    const content = `--- QUALIFICAÇÃO DE NOTA TÉCNICA - ${props.nota_tecnica.id} ---
    
>> PROPOSIÇÃO: ${props.acao_preparatoria.proposicao}
    
>> POSICIONAMENTO: ${posicionamento}

>> PRIORIDADE: ${tipoImpacto + ' - ' + impacto}

>> PROPOSTA MAQUI(IA): ${formattedTextResIA}`;

    /** Gerar PDF **/
    const pdf = new jsPDF();
    const pageWidth = pdf.internal.pageSize.width;
    const pageHeight = pdf.internal.pageSize.height;
    const margin = 10;
    const lineHeight = 7;
    const maxLineWidth = pageWidth - margin * 2;

    const lines = pdf.splitTextToSize(content, maxLineWidth);

    let cursorY = 20;

    pdf.setFont("Times", "Roman");
    pdf.setFontSize(12);

    lines.forEach((line) => {
      if (cursorY + lineHeight > pageHeight - margin) {
        pdf.addPage();
        cursorY = margin;
      }
      pdf.text(line, margin, cursorY);
      cursorY += lineHeight;
    });

    // Gera o arquivo PDF
    const pdfBlob = pdf.output('blob');
    const pdfFile = new File(
      [pdfBlob],
      `qualificacao_nota_tecnica_${props.nota_tecnica.id}.pdf`,
      { type: 'application/pdf' }
    );

    /** Gerar DOCX **/
    const paragraphs = content.split('\n').map(
      (line) =>
        new Paragraph({
          text: line.trim(),
          spacing: {
            after: 200,
          },
        })
    );

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: paragraphs,
        },
      ],
    });
    const docBlob = await Packer.toBlob(doc);
    const docFile = new File([docBlob], `qualificacao_nota_tecnica_${props.nota_tecnica.id}.docx`, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

    /** Função para download **/
    const downloadFile = (file: File) => {
      const url = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };

    /** Beixar arquivos **/
    downloadFile(pdfFile);
    downloadFile(docFile);

    /** Anexar os arquivos gerados à nota tecnica **/
    await UploadFilesService.upload(
      [pdfFile, docFile].map((item) => item),
      EAnexoModulos.NotaTecnica,
      props.nota_tecnica.id
    )
      .then((response) => {
        if (response?.success && response?.data && response?.data?.length > 0) {
          NewAlert('success', 'Sucesso no envio dos arquivos!');
        } else {
          console.error(response);
          NewAlert('error', 'Falha no upload dos arquivos!');
        }
      })
      .catch((err) => {
        console.error(err);
        NewAlert('error', 'Falha no upload dos arquivos!');
      });
    setLoadingAnexo(false);

  };

  return (
    <div>
      <Link
        href="#"
        onClick={handleClickOpen}
        underline="none"
        style={{
          fontWeight: pallet.fontWeight.subtitle,
          fontSize: `${pallet.general.size14}`,
          color: `${pallet.color.secundary.font}`,
        }}
      >
        {props.children ? props.children : ""}
      </Link>

      <BootstrapDialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Grid container>
          <Grid item style={{ width: isMobile ? '100%' : '70%' }}>
            {status !== "Preenchido" && (
              <>
                <BootstrapDialogTitle
                  id="customized-dialog-title"
                  onClose={handleClose}
                >
                  {titleDialog}
                </BootstrapDialogTitle>

                <Grid
                  style={{ fontFamily: pallet.fontFamily.general, width: "100%" }}
                >
                  <Grid
                    container
                    style={{
                      fontWeight: pallet.general.weight_600,
                      alignContent: "center",
                      alignItems: "center",
                      fontFamily: pallet.fontFamily.general,
                      fontSize: pallet.general.size12,
                      paddingTop: "15px",
                      justifyContent: 'space-between'
                    }}
                  >
                    <Grid item>
                      <Grid container
                        style={{
                          paddingLeft: `${isMobile ? "0px" : "40px"}`,
                          fontWeight: pallet.general.weight_600,
                          alignContent: "center",
                          alignItems: "center",
                          fontFamily: pallet.fontFamily.general,
                          fontSize: pallet.general.size12,
                          paddingTop: "15px",
                        }}>
                        <Grid style={{ marginTop: "4px" }} item>
                          <FeedIcon style={{ color: pallet.general.color1 }} />
                        </Grid>
                        <Grid style={{ marginLeft: "10px" }} item>
                          AÇÃO PREPARATÓRIA
                        </Grid>
                        <Grid
                          style={{ marginLeft: "5px", color: pallet.general.color1 }}
                        >
                          {" "}
                          {props.acao_preparatoria?.prazo
                            ? formatDate(
                              new Date(props.acao_preparatoria.prazo),
                              "dd/MM/yyyy"
                            )
                            : ""}
                        </Grid>
                        <Grid style={{ marginLeft: "5px" }}>
                          {" "}
                          | {props.acao_preparatoria?.proposicao}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {/* Botão ou Link que abre o Modal */}
                      <Grid
                        lg={12}
                        style={{
                          fontSize: pallet.general.size14,
                          marginLeft: "20px",
                          marginRight: "40px",
                          marginTop: "2px",
                          display: "flex",
                          cursor: "pointer",
                          border: '1px solid #3f3f3f',
                          padding: '5px 15px 5px 15px',
                          borderRadius: '7px'
                        }}
                        onClick={handleOpenPDF}
                      >
                        <Grid style={{ paddingTop: "4px", color: '#3f3f3f' }}>
                          <PictureAsPdfIcon />
                        </Grid>
                        &nbsp;&nbsp;&nbsp;
                        <Grid style={{ paddingTop: "7px" }}>
                          {uri_teor ? (
                            <a
                              style={{
                                textDecoration: "none",
                                color: '#3f3f3f',
                                fontWeight: pallet.general.weight_600,
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              Visualizar Proposta de Lei
                            </a>
                          ) : (
                            "Texto Base não informado"
                          )}
                        </Grid>
                      </Grid>

                      {/* Modal */}
                      <Dialog
                        open={openPDF}
                        onClose={handleClosePDF}
                        fullWidth
                        maxWidth="lg" // Tamanho large
                      >
                        <DialogTitle>
                          Detalhes do Texto Base
                          <IconButton
                            style={{ position: "absolute", right: 8, top: 8 }}
                            onClick={handleClosePDF}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <Grid style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
                            {uri_teor ? (
                              <iframe
                                src={uri_teor?.replace("http:", "https:")}
                                style={{
                                  width: "100%",
                                  height: "600px",
                                  border: "none",
                                }}
                                title="PDF - PROPOSTA DE LEI"
                              />
                            ) : (
                              <p>Texto Base não informado</p>
                            )}
                          </Grid>
                        </DialogContent>
                      </Dialog>
                    </Grid>
                    {/* {props.editLayout === true ? null :
                      <Grid item>
                        <Button
                          variant="contained"
                          size="large"
                          style={{
                            paddingTop: '10px',
                            marginTop: isMobile ? '14px' : null,
                            marginLeft: isMobile ? '14px' : null,
                            textTransform: 'uppercase',
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.general.size12}`,
                            fontWeight: pallet.general.weight_600,
                            border: "none",
                            backgroundColor: `${pallet.backgroundColorQuintenary}`,
                            color: `${pallet.textColorTertiary}`,
                            marginRight: '20px'
                          }}
                          onClick={ativarModoAssitente}
                          startIcon={
                            <QuestionAnswerIcon style={{
                              color: `${pallet.color.secundary.font}`
                            }}
                            />}
                        >
                          {comAssist ? 'Preencher com Formulário' : 'Preencher com Assistente'}
                        </Button>
                      </Grid>
                    } */}
                  </Grid>
                  <Grid>
                    <Divider
                      variant="fullWidth"
                      style={{ paddingTop: `${isMobile ? "15px" : "15px"}` }}
                    />
                  </Grid>


                  <Grid
                    container
                    xl={12}
                    lg={12}
                    xs={12}
                    style={{ paddingTop: "20px" }}
                  >
                    {comAssist ?
                      loadingResIA ?
                        <>
                          <CircularLoading
                            size={100}
                            top={isMobile ? "37%" : "50%"}
                            left={isMobile ? "6%" : "40%"}
                            zIndex={2}
                          />
                          <Grid style={{ padding: '20px', width: '800px', textAlign: 'center' }}>
                            <TypeAnimation
                              key={`step-${step}-question`}
                              sequence={[
                                3000,
                                'Aguarde um pouco! O Maqui está considerando suas informações e gerando a resposta mais coerente.',
                              ]}
                              cursor={false}
                              speed={50}
                              style={{ fontSize: '1.3em', display: 'block' }}
                            />
                          </Grid>
                        </>
                        :
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          style={{ width: '1100px', margin: '0 auto', padding: '20px' }}
                        >
                          {selectedOptions[0] === '0' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-greeting`}
                                    sequence={[
                                      `Olá ${user.nome}, tudo bem?`,
                                      2000,
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                  <TypeAnimation
                                    key={`step-${step}-question`}
                                    sequence={[
                                      3000,
                                      'O que você achou desta proposição que você foi convidado a avaliar os impactos em nome da sua unidade de negócio?',
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>

                              <Grid item style={{ padding: '50px 50px 20px 50px' }}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                  <FormGroup>
                                    <Grid style={{ width: '100%' }}>
                                      {alternativas1.map((item, index) => (
                                        <Grid item key={index}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={posicionamento === item.value} // Sincroniza com posicionamento
                                                onChange={(e) => handleChange(e, item.cod, item.value)}
                                                style={{ marginTop: '-12px' }}
                                              />
                                            }
                                            label={item.texto}
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'flex-start',
                                              '& .MuiTypography-root': {
                                                fontFamily: `${pallet.general.fontFamily}, sans-serif`,
                                                fontWeight: 100,
                                                fontSize: 13,
                                                marginBottom: 2,
                                              },
                                            }}
                                          />
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.0' || selectedOptions[0] === '2.0' || selectedOptions[0] === '3.0' || selectedOptions[0] === '4.0' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={selectedOptions[0] === '1.0' ? [
                                      'Perfeito. Você poderia dizer por que essa proposição é prejudicial pra gente?',
                                    ] : selectedOptions[0] === '2.0' ? [
                                      'Perfeito. Você poderia dizer por que essa proposição é prejudicial e de que maneira, se ela for ajustada, poderia se tornar viável?',
                                    ] : selectedOptions[0] === '3.0' ? [
                                      'Perfeito. Você poderia dizer por que essa proposição é positiva pra gente?',
                                    ] : selectedOptions[0] === '4.0' ? [
                                      'Perfeito. Você poderia dizer por que essa proposição é positiva e de que maneira, se ela for ajustada, poderia ficar ainda melhor?',
                                    ] : []}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: isMobile ? '25px 25px 10px 25px' : '50px 50px 20px 50px' }}>
                                <InputMultilineTextField
                                  type="text"
                                  maxRows={6}
                                  minRows={4}
                                  value={justificativa1}
                                  onChange={handleJustificativa1Change}
                                  id="just1"
                                  label="Escreva o porque neste campo"
                                />
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.1' || selectedOptions[0] === '2.1' || selectedOptions[0] === '3.1' || selectedOptions[0] === '4.1' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={[
                                      'Entendo... Se outro motivo que queira mensionar, escreva. Caso contrário, clique em próximo?',
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: isMobile ? '25px 25px 10px 25px' : '50px 50px 20px 50px' }}>
                                <InputMultilineTextField
                                  type="text"
                                  maxRows={6}
                                  minRows={4}
                                  value={outrosMotivos}
                                  onChange={handleOutrosMotivosChange}
                                  id="outro_motivo"
                                  label="Escreva aqui quais são esses motivos"
                                />
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.2' || selectedOptions[0] === '2.2' || selectedOptions[0] === '3.2' || selectedOptions[0] === '4.2' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={selectedOptions[0] === '1.2' || selectedOptions[0] === '3.2' ?
                                      ['Nessa linha de pensamento, se desejar adicionar alguns argumentos, escreva-os abaixo ou, caso não seja necessário, clique em próximo']
                                      : selectedOptions[0] === '2.2' || selectedOptions[0] === '4.2' ?
                                        ['Nessa linha de pensamento, quais seriam os pontos da proposição que você gostaria de modificar? Podem ser artigos específicos... Se quiser colar os pontos aqui, também pode']
                                        : []
                                    }
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: '50px 50px 20px 50px' }}>
                                <InputMultilineTextField
                                  type="text"
                                  maxRows={6}
                                  minRows={4}
                                  value={selectedOptions[0] === '1.2' || selectedOptions[0] === '3.2' ? argumentos : pontosModificacao}
                                  onChange={selectedOptions[0] === '1.2' || selectedOptions[0] === '3.2' ? handleArgumentosChange : handlePontosModificacaoChange}
                                  id="argumentos_modificacao"
                                  label={selectedOptions[0] === '1.2' || selectedOptions[0] === '3.2' ? "Escreva seus argumanetos aqui" : "Escreva os pontos da proposição que você gostaria de modificar"}
                                />
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.3' || selectedOptions[0] === '2.3' || selectedOptions[0] === '3.3' || selectedOptions[0] === '4.3' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={[
                                      'Você poderia estimar, mesmo que não seja exato, o impacto financeiro caso essa medida seja aprovada?',
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: '50px 50px 20px 50px' }}>
                                <Grid container>
                                  <GridColumn item md={4} xs={12} lg={6} xl={6}>
                                    <SelectTextFieldA
                                      options={impactoFinanceiroItens}
                                      // focused={true}
                                      placeholder={"Selecione unidade de negócio ou usuário"}
                                      value={tipoImpacto}
                                      // defaultValue={tipoImpacto}
                                      onChange={handleImpactoChange}
                                      label={"IMPACTO FINANCEIRO"}
                                      id={"impacto-demandas"}
                                    />
                                  </GridColumn>
                                  <GridColumn item md={4} xs={12} lg={6} xl={6}>
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      focused={true}
                                      // placeholder={"Digite"}
                                      value={impacto}
                                      onChange={handleImpactoValorChange}
                                      label="VALOR DO IMPACTO"
                                      id={"impacto-valor-demandas"}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <AttachMoneyIcon />
                                          </InputAdornment>
                                        ),
                                        inputMode: "decimal",
                                        style: { fontFamily: `${pallet.general.fontFamily}` },
                                        classes: { notchedOutline: classes.notchedOutline },
                                      }}
                                      InputLabelProps={{
                                        style: {
                                          color: "gray",
                                        },
                                      }}
                                      style={{ width: "100%" }}
                                    />
                                  </GridColumn>
                                </Grid>
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.4' || selectedOptions[0] === '2.4' || selectedOptions[0] === '3.4' || selectedOptions[0] === '4.4' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={[
                                      'Na sua opinião, qual é o nível de prioridade que essa matéria deve merecer?',
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: '50px 50px 20px 50px' }}>
                                <GridColumn item md={4} xs={12} lg={12} xl={12}>
                                  <SelectTextFieldA
                                    value={prioridade}
                                    onChange={handlePrioridade}
                                    options={prioridadeItens}
                                    label={"PRIORIDADE"}
                                    id={"prioridade-nota-tecnica"}
                                  />
                                </GridColumn>
                                {prioridade === "Máxima" && (
                                  <Grid
                                    item
                                    md={12}
                                    xs={12}
                                    style={{ marginLeft: "16px", paddingTop: "15px" }}
                                  >
                                    <FormGroup
                                      style={{ fontFamily: `${pallet.fontFamily.general}` }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            defaultChecked={confirmacao}
                                            value={confirmacao}
                                            onChange={handleConfirmacaoChange}
                                            style={{
                                              color: `${pallet.color.secundary.font}`,
                                            }}
                                          />
                                        }
                                        label="Aprovado pela diretoria."
                                      />
                                    </FormGroup>
                                    <Grid
                                      style={{
                                        fontSize: pallet.general.size12,
                                        color: "red",
                                      }}
                                    >
                                      Projetos com prioridade máxima exigem a aprovação da
                                      diretoria do seu departamento.
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.5' || selectedOptions[0] === '2.5' || selectedOptions[0] === '3.5' || selectedOptions[0] === '4.5' ? (
                            <>
                              <Grid container>
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'left', fontWeight: 300, padding: '0px 20px 0px 20px' }}>
                                  <TypeAnimation
                                    key={`step-${step}-response`}
                                    sequence={[
                                      'Pra terminar, escreva uma breve justificativa com base em todos os seus posicionamentos respondidos sobre está proposta.',
                                    ]}
                                    cursor={false}
                                    speed={50}
                                    style={{ fontSize: '1.3em', display: 'block' }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item style={{ padding: '50px 50px 20px 50px' }}>
                                <InputMultilineTextField
                                  type="text"
                                  maxRows={6}
                                  minRows={4}
                                  maxLength={10000}
                                  value={justificativaII}
                                  onChange={handleJustificativaChange}
                                  id="justificativa"
                                  label="Escreva sua justificativa aqui"
                                />
                              </Grid>
                            </>
                          ) : selectedOptions[0] === '1.6' || selectedOptions[0] === '2.6' || selectedOptions[0] === '3.6' || selectedOptions[0] === '4.6' ? (
                            <Grid style={{ textAlign: 'center', fontWeight: 300, marginTop: resIA && resIA !== '' ? '0px' : '20px' }}>
                              {resIA && resIA.length > 0 ?
                                <Grid
                                  item
                                  lg={12}
                                  xs={12}
                                  style={{
                                    marginTop: "20px",
                                    paddingLeft: `${isMobile ? "8px" : "16px"}`,
                                    paddingRight: `${isMobile ? "8px" : "16px"}`,
                                  }}
                                >
                                  <Grid style={{
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    fontSize: `${pallet.general.size12}`,
                                    fontWeight: 'bolder',
                                    marginBottom: '15px'
                                  }}>
                                  </Grid>
                                  <InputMultilineTextField
                                    type={"text"}
                                    maxRows={11}
                                    minRows={4}
                                    value={resIA}
                                    onChange={handleResIAChange}
                                    id={"resposta_proposta_ia"}
                                    label="MAQUI TROUXE ESSA RESPOSTA PARA VOCÊ:"
                                  />
                                </Grid>
                                :
                                <TypeAnimation
                                  key={`step-${step}-response`}
                                  sequence={[
                                    'Obrigado pela atenção com as informações. Clique no botão Gerar para eu escrever um texto que suporte suas escolhas e posicionamentos. Fique a vontade pra editar!',
                                  ]}
                                  cursor={false}
                                  speed={50}
                                  style={{ fontSize: '1.3em', display: 'block' }}
                                />
                              }
                            </Grid>
                          ) : null}
                          {selectedOptions[0] === '0' ? null
                            :
                            <Grid container justifyContent={'space-between'} style={{ padding: '20px 30px 0px 50px' }}>
                              <Grid item>
                                <Button onClick={() => controlSteps('back')} variant="contained"
                                  style={{
                                    width: '100%',
                                    color: `${pallet.general.color3}`,
                                    backgroundColor: `${pallet.general.color2}`,
                                    fontSize: '13px',
                                    fontWeight: pallet.general.weight_300,
                                    fontFamily: `${pallet.general.fontFamily}`,
                                    padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                                  }}
                                >
                                  Voltar
                                </Button>
                              </Grid>
                              {selectedOptions[0] === '1.6' || selectedOptions[0] === '2.6' || selectedOptions[0] === '3.6' || selectedOptions[0] === '4.6' ?
                                <Grid item>
                                  <Button onClick={() => gerarPropostaEmenda()} variant="contained"
                                    style={{
                                      width: '100%',
                                      color: `${pallet.general.color3}`,
                                      backgroundColor: `${pallet.general.color2}`,
                                      fontSize: '13px',
                                      fontWeight: pallet.general.weight_300,
                                      fontFamily: `${pallet.general.fontFamily}`,
                                      padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                                    }}
                                  >
                                    {posicionamento === 'Contrário com emenda saneadora' || posicionamento === 'Favorável com emenda saneadora' || posicionamento === 'Favorável ao parecer do relator com emenda' || posicionamento === 'Contrário ao parecer do relator com emenda' ? 'Gerar Proposta' : 'Gerar Análise'}
                                  </Button>
                                </Grid>
                                :
                                <Grid item>
                                  <Button onClick={() => controlSteps('next')} variant="contained"
                                    style={{
                                      width: '100%',
                                      color: `${pallet.general.color3}`,
                                      backgroundColor: `${pallet.general.color2}`,
                                      fontSize: '13px',
                                      fontWeight: pallet.general.weight_300,
                                      fontFamily: `${pallet.general.fontFamily}`,
                                      padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                                    }}
                                  >
                                    Próximo
                                  </Button>
                                </Grid>
                              }
                              {resIA.length > 0 ?
                                <Grid item>
                                  <Button
                                    onClick={() => salvarAnexoIA()}
                                    variant="contained"
                                    style={{
                                      width: '100%',
                                      color: `${pallet.general.color2}`,
                                      backgroundColor: `${pallet.general.color3}`,
                                      fontSize: '13px',
                                      fontWeight: pallet.general.weight_300,
                                      fontFamily: `${pallet.general.fontFamily}`,
                                      padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"}`,
                                    }}
                                  >
                                    Salvar Anexo - Resposta do MAQUI
                                  </Button>
                                </Grid>
                                :
                                null}
                            </Grid>
                          }
                        </Grid>
                      :
                      <>
                        <Grid
                          style={{
                            marginBottom: "15px",
                            fontWeight: pallet.general.weight_600,
                            fontFamily: pallet.fontFamily.general,
                            fontSize: pallet.general.size12,
                            paddingLeft: `${isMobile ? "5px" : "40px"}`,
                          }}
                        >
                          INFORMAÇÕES
                        </Grid>

                        <Grid
                          container
                          style={{
                            paddingLeft: `${isMobile ? "0px" : "24px"}`,
                            paddingRight: `${isMobile ? "0px" : "24px"}`,
                          }}
                        >
                          <GridColumn item md={4} xs={12}>
                            <SelectTextFieldA
                              options={posicionamentosItens}
                              // placeholder={"Selecione departamento ou usuário"}
                              value={posicionamento}
                              onChange={handlePosicionamentoChange}
                              label={"POSICIONAMENTO DO GESTOR"}
                              id={"responsavel-posicionamento"}
                            />
                          </GridColumn>
                          <GridColumn item md={4} xs={12}>
                            <SelectTextFieldA
                              options={impactoFinanceiroItens}
                              // focused={true}
                              placeholder={"Selecione unidade de negócio ou usuário"}
                              value={tipoImpacto}
                              // defaultValue={tipoImpacto}
                              onChange={handleImpactoChange}
                              label={"IMPACTO FINANCEIRO"}
                              id={"impacto-demandas"}
                            />
                          </GridColumn>
                          <GridColumn item md={4} xs={12}>
                            <TextField
                              variant="outlined"
                              type="number"
                              focused={true}
                              // placeholder={"Digite"}
                              value={impacto}
                              onChange={handleImpactoValorChange}
                              label="VALOR DO IMPACTO"
                              id={"impacto-valor-demandas"}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                  </InputAdornment>
                                ),
                                inputMode: "decimal",
                                style: { fontFamily: `${pallet.general.fontFamily}` },
                                classes: { notchedOutline: classes.notchedOutline },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "gray",
                                },
                              }}
                              style={{ width: "100%" }}
                            />
                          </GridColumn>
                          <GridColumn item md={4} xs={12}>
                            <SelectTextFieldA
                              value={prioridade}
                              onChange={handlePrioridade}
                              options={prioridadeItens}
                              label={"PRIORIDADE"}
                              id={"prioridade-nota-tecnica"}
                            />
                          </GridColumn>
                          {prioridade === "Máxima" && (
                            <Grid
                              item
                              md={12}
                              xs={12}
                              style={{ marginLeft: "16px", paddingTop: "15px" }}
                            >
                              <FormGroup
                                style={{ fontFamily: `${pallet.fontFamily.general}` }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      defaultChecked={confirmacao}
                                      value={confirmacao}
                                      onChange={handleConfirmacaoChange}
                                      style={{
                                        color: `${pallet.color.secundary.font}`,
                                      }}
                                    />
                                  }
                                  label="Aprovado pela diretoria."
                                />
                              </FormGroup>
                              <Grid
                                style={{
                                  fontSize: pallet.general.size12,
                                  color: "red",
                                }}
                              >
                                Projetos com prioridade máxima exigem a aprovação da
                                diretoria do seu departamento.
                              </Grid>
                            </Grid>
                          )}

                          <Grid

                            item
                            lg={12}
                            xs={12}
                            style={{
                              marginTop: "20px",
                              paddingLeft: `${isMobile ? "8px" : "16px"}`,
                              paddingRight: `${isMobile ? "8px" : "16px"}`,
                            }}
                          >
                            <Grid

                              style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: 'bolder',
                                marginBottom: '15px'
                              }}>
                              {"TOTAL DE CARACTERES DA JUSTIFICATIVA: " + justificativaTotal + "(MÁXIMO 10000 CARACTERES)"}
                            </Grid>
                            <Grid>



                              <ReactQuill theme="snow" value={justificativa.field_description}
                                onChange={(content) => {
                                  // Remove as tags html
                                  const plainText = content.replace(/<\/?[^>]+(>|$)/g, "");

                                  // Checa de o conteudo atingiu o limite
                                  if (plainText.length <= 10000) {
                                    setJustificativa({ field_description: content });
                                  } else {
                                    // Se aingiu não altera o estado ca const
                                    setJustificativa({ ...justificativa, field_description: justificativa.field_description });
                                  }
                                }}
                                modules={modulos} />

                              {/* <Editor 
                              value={justificativa}
                               onTextChange={handleJustificativaChange2} 
                               headerTemplate={header}
                               />  */}
                              {/* <InputMultilineTextField
                              type={"text"}
                              maxRows={6}
                              minRows={4}
                              maxLength={10000}
                              value={justificativa}
                              onChange={handleJustificativaChange}
                              id={"justificativa"}
                              label="ARGUMENTOS PARA JUSTIFICAR POSICIONAMENTO"
                            /> */}
                            </Grid>
                          </Grid>
                          {resIA && resIA.length > 0 ?
                            <Grid
                              item
                              lg={12}
                              xs={12}
                              style={{
                                marginTop: "20px",
                                paddingLeft: `${isMobile ? "8px" : "16px"}`,
                                paddingRight: `${isMobile ? "8px" : "16px"}`,
                              }}
                            >
                              <Grid style={{
                                fontFamily: `${pallet.general.fontFamily}`,
                                fontSize: `${pallet.general.size12}`,
                                fontWeight: 'bolder',
                                marginBottom: '15px',
                              }}>
                              </Grid>
                              <InputMultilineTextField
                                type={"text"}
                                maxRows={11}
                                minRows={4}
                                value={resIA}
                                onChange={handleResIAChange}
                                id={"resposta_proposta_ia"}
                                label="MAQUI TROUXE ESSA RESPOSTA PARA VOCÊ:"
                              />
                            </Grid>
                            :
                            null
                          }
                          <Grid
                            item
                            lg={12}
                            xs={12}
                            style={{
                              marginTop: "40px",
                              paddingLeft: `${isMobile ? "8px" : "16px"}`,
                              paddingRight: `${isMobile ? "8px" : "16px"}`,
                            }}
                          >
                            {departamento && (

                              <>
                                <Grid
                                  style={{
                                    marginBottom: "15px",
                                    fontWeight: pallet.general.weight_600,
                                    fontFamily: pallet.fontFamily.general,
                                    fontSize: pallet.general.size12,
                                    // paddingLeft: `${isMobile ? "5px" : "40px"}`,
                                  }}
                                >
                                  DESEJA INCLUIR OUTRAS UNIDADES DE NEGÓCIO/RESPONSÁVEIS PARA ESTA NOTA TÉCNICA ?
                                </Grid>

                                <InputDropDownCheckBoxNestedAcaoPrep
                                  items={departamento}
                                  onChange={handleSetUserList}
                                  label={"Solicitacao de"}
                                  selectedItems={userList}
                                  deptList={handleSetDeptList}
                                  lockedItems={lockedUserList}
                                />
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    }
                  </Grid>
                  {posicionamento || justificativa1 || outrosMotivos || argumentos || impacto || prioridade || pontosModificacao || justificativa
                    ?
                    <>
                      <Grid>
                        <Divider
                          variant="fullWidth"
                          style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }}
                        />
                      </Grid>
                      <Grid
                        style={{
                          paddingTop: `${isMobile ? "15px" : "40px"}`,
                          paddingLeft: `${isMobile ? "10px" : "24px"}`,
                          paddingRight: `${isMobile ? "0px" : "24px"}`,
                        }}
                      >
                        <Grid
                          xs={12}
                          lg={12}
                          style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                        >
                          <Grid
                            style={{
                              fontWeight: pallet.fontWeight.subtitle,
                              fontSize: `${pallet.general.size12}`,
                              color: `${pallet.color.tertiary.font}`,
                            }}
                          >
                            HISTÓRICO DE ESCOLHAS E INFORMAÇÕES
                          </Grid>
                          {posicionamento ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Posicionamento:</span> {posicionamento}
                            </Grid>
                            :
                            null}
                          {justificativa1 ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Primeira Justificativa:</span> {justificativa1}
                            </Grid>
                            :
                            null}
                          {outrosMotivos ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Motivos Descritos:</span> {outrosMotivos}
                            </Grid>
                            :
                            null}
                          {argumentos ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Argumentos Extras:</span> {argumentos}
                            </Grid>
                            :
                            null}
                          {impacto ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Impacto Financeiro:</span> {impacto}
                            </Grid>
                            :
                            null}
                          {prioridade ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Prioridade:</span> {prioridade}
                            </Grid>
                            :
                            null}
                          {pontosModificacao ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px' }}>
                              <span style={{ fontWeight: 600 }}>Pontos de Modificação:</span> {pontosModificacao}
                            </Grid>
                            :
                            null}
                          {justificativa ?
                            <Grid style={{ fontSize: '13px', marginTop: '15px', wordBreak: "break-all" }}>
                              <span style={{ fontWeight: 600 }}>Justificativa Final:</span> {justificativa.field_description.replace(/(<([^>]+)>)/gi, "")}
                            </Grid>
                            :
                            null}
                        </Grid>
                      </Grid>
                    </>
                    :
                    null}
                  <Grid>
                    <Divider
                      variant="fullWidth"
                      style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }}
                    />
                  </Grid>
                  <Grid
                    style={{
                      paddingTop: `${isMobile ? "15px" : "40px"}`,
                      paddingLeft: `${isMobile ? "10px" : "24px"}`,
                      paddingRight: `${isMobile ? "0px" : "24px"}`,
                    }}
                  >
                    <Grid
                      xs={12}
                      lg={12}
                      style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}
                    >
                      <Grid
                        style={{
                          fontWeight: pallet.fontWeight.subtitle,
                          fontSize: `${pallet.general.size12}`,
                          color: `${pallet.color.tertiary.font}`,
                        }}
                      >
                        ANEXOS
                      </Grid>
                      {loadingAnexo ?
                        <CircularLoading
                          size={100}
                          top={isMobile ? "37%" : "50%"}
                          left={isMobile ? "6%" : "40%"}
                          zIndex={2}
                        />
                        :
                        <Grid style={{ marginTop: "10px" }}>
                          <Grid className="container">
                            <AnexosComponent
                              label=""
                              modulo={EAnexoModulos.NotaTecnica}
                              id_referencia={props.nota_tecnica.id}
                              //onAfterUpload={handleAfterUpload}
                              canEdit={true}
                              autosave={true}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                {
                  loading ?
                    <CircularLoading
                      size={100}
                      top={isMobile ? "37%" : "90%"}
                      left={isMobile ? "6%" : "16%"}
                      zIndex={2}
                    />

                    :
                    null
                }
                <Grid>
                  <Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  sx={{
                    backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                    height: `${isMobile ? "50px" : "98px"}`,
                    borderRadius: "5px",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      xs={12}
                      style={{
                        paddingRight: `${isMobile ? "20px" : "40px"}`,
                        textAlignLast: "end",
                        padding: `${isMobile ? "10px 20px 10px 20px" : "10px 20px 10px 20px"
                          }`,
                      }}
                    >
                      <SolidButton
                        onClick={salvarPosicionamento}
                        color={`${pallet.textColorPrimary}`}
                        // onChange={props?.reloadLista}
                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                        fontSize={`${pallet.general.size14}`}
                        title={"SALVAR"}
                        disabled={lockSaveButton}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item style={{ width: isMobile ? '100%' : '30%', marginTop: "10px", height: '100%' }}>
            <Grid
              container
              direction="column"
              style={{
                height: '100%',
                overflow: 'hidden', // Evita overflow externo
                padding: '20px',
                borderRadius: '5px',
                backgroundColor: '#d3d3d3',
                fontSize: '13px',
                lineHeight: '23px',
              }}
            >
              <Grid
                style={{
                  fontFamily: `${pallet.fontFamily.general}`,
                  fontWeight: pallet.general.weight_bold,
                  fontSize: `${pallet.general.size18}`,
                  wordBreak: "break-word"
                }}
              >
                Texto de Referência da {props.acao_preparatoria?.proposicao}
              </Grid>
              <Grid
                style={{
                  flex: 1,
                  fontFamily: `${pallet.fontFamily.general}`,
                  overflowY: 'auto',
                  paddingTop: '30px',
                  scrollbarWidth: 'thin',
                  msOverflowStyle: 'none',
                  fontWeight: 100,
                }}
              >
                {textoEmenta}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
