import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Divider, ListItemText } from "@mui/material";
import { usePallet } from "../../../contexts/PalletContext";
import InputDatePicker from "../../../components/InputDatePicker";
import InputMultilineTextField from "../../../components/InputMultilineTextField";
import AnexosComponent, { EAnexoModulos } from "../../../components/Anexos";
import SolidButton from "../../../components/SolidButton";
import { isMobile } from "react-device-detect";
import EditIcon from "@mui/icons-material/Edit";
import ProposicaoAcontecimentosService from "../../../services/ProposicaoAcontecimentosService";
import { useAlert } from "../../../contexts/AlertContext";
import RoomIcon from "@mui/icons-material/Room";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InputTextField from "../../../components/InputTextField";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { formatDate } from "../../../utils/dateUtils";
import { format } from "date-fns";
import ButtonIconSalvar from "../../../components/ButtonIconSalvar";
import ButtonDialogConfirmation from "../../../components/ButtonDialogConfirmation";
import * as Yup from "yup";
import CitacoesService from "../../../services/CitacoesService";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ImageLoader from "../../../components/ImageLoader";
import SelectTextFieldA from "../../../components/SelectTextFieldA";
import UsuarioService from "../../../services/UsuarioService";
import TabelasService from "../../../services/TabelasService";
import useStyles from '../../../components/layout/Styles';
import { InputDropDownCheckBoxNested } from "../../../components/CheckBoxNested";
import { ICheckBoxNestedItemListaUnica, InputDropDownCheckBoxNestedListaUnica } from "../../../components/CheckBoxNestedListaUnica";
import { arraysEquals } from '../../../utils/array.utils';
import { useAuthorization } from "../../../contexts/AuthorizationContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function CustomizedDialogs(props: {
  reloadList?: () => void;
  closeMenu?: () => void;
}) {
  const { NewAlert } = useAlert();
  const [open, setOpen] = React.useState(false);
  const { pallet } = usePallet();
  const [dateAcontecimentos, setDateAcontecimentos] = React.useState();
  const [dataAcontecimentosAgenda, setDataAcontecimentosAgenda] =
    React.useState();
  const [nomeDepartamento, setNomeDepartamento] = React.useState("");
  const [emailDepartamento, setEmailDepartamento] = React.useState("");
  const [usuario, setUsuario] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [permissao, setPermissao] = React.useState("");
  const [departamento, setDepartamento] = React.useState("");
  const [usuarios, setUsuarios] = React.useState<Array<ICheckBoxNestedItemListaUnica>>();
  const [userList, setUserList] = React.useState<Array<number>>([]);
  const [responsavelList, setResponsavelList] = React.useState<Array<number>>([]);

  const [onSave, setOnSave] = React.useState(false);
  const [idRef, setIdRef] = React.useState();
  const [idAgRef, setIdAgRef] = React.useState<string>();
  const [data, setData] = React.useState({});
  const [horas, setHoras] = React.useState<string>();
  const [local, setLocal] = React.useState<string>();
  const [error, setError] = React.useState<
    Array<{ name: string; err: string }>
  >([]);
  const [imageGet, setImageGet] = React.useState("");
  const [imageSelected, setImageSelected] = React.useState({});
  const classes = useStyles();

  const handleDataAcontecimentosAgendaChange = (dataAcEve) => {
    setDataAcontecimentosAgenda(dataAcEve);
  };

  const handleDepartamentoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartamento(event.target.value);
  };
  const handlePermissaoOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPermissao(event.target.value);
  };

  const KeywordSchema = Yup.object({
    descricao: Yup.string()
      .required({ name: "keyword", err: "Digite a palavra-chave" })
      .min(2),
  });

  const handleClickOpen = async () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onImageSelect = (file) => {
    setImageSelected(file);
  };
  const handleChangeNome = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNomeDepartamento(event.target.value);
  };
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailDepartamento(event.target.value);
  };
 const handleChangeUsuario = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsuario(event.target.value);
  };
  const handleChangeSenha = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSenha(event.target.value);
  };

  const postCitacao = async (e) => {
    e.preventDefault();
    const data = {
      descricao: nomeDepartamento,
      email: emailDepartamento,
      membros: userList,
      responsaveis: responsavelList
    };
    setError(() => []);
    // data &&
    //   KeywordSchema.validate(data, { abortEarly: false }).catch((err) =>
    //     setError(() => err.errors)
    //   );
    setData(data);
    // KeywordSchema.validate(data).catch(function (err) {});
    // if (KeywordSchema.isValidSync(data)) {
      await UsuarioService.CreateGerDpto(data).then((res) => {

        let idCitacao = res;
        if (res === -1) {
          NewAlert("error", "Unidade de negócio já cadastrada.");
        }
        
      else  if (res) {
          NewAlert("success", "Unidade de negócio cadastrada com sucesso.");
          setNomeDepartamento("");
          handleClose();
        }
        
        
        else {
          NewAlert(
            "error",
            "Erro ao cadastrar, verifique os campos e salve novamente."
          );
        }
      });
    // }
  };

  async function handleOnAfterUpload() {
    if (idRef) {
      setIdRef(undefined);
      setOnSave(false);
    }
  }
  const ErrorMessage = (props: {
    name: string;
    errors: Array<{ name: string; err: string }>;
  }) => {
    const itemError = props.errors.filter((err) => err.name === props.name);
    return (
      <div style={{ color: "red", fontSize: "11px", marginTop: "5px" }}>
        {itemError.length === 1 && itemError[0].err}
      </div>
    );
  };


  React.useEffect(() => {
    if(open) {
      UsuarioService.GetNestedCheckBoxUsuario().then(async res => {
        
        const comboItens = res.map(usuarios => {
          return {
            id: usuarios.id,
            label: usuarios.nome,
            value: false
          }
        })
        setUsuarios(comboItens)
      },
      )
    }else{
      setUserList([])
    }
  }, [open]);

  const handleSetUserList = (event: any, id: number) => {
    if (event === true) {
      userList.push(id)
    }
    else {
      userList.splice(userList.indexOf(id), 1)
    }
    
    }


    const handleSetResponsaveisList = (event: any, id: number) => {
      if (event === true) {
        responsavelList.push(id)
      }
      else {
        responsavelList.splice(userList.indexOf(id), 1)
      }

      
      }
      const { user } = useAuthorization();

  
  return (
    <div>
      <ButtonIconSalvar
        onClick={() => user.id_permissao !== 3 ? handleClickOpen() : NewAlert(
          "error",
          "Não é possível realizar está ação com este perfil.")}
        tipo={"cadastrar_usuario"}
        backgroundColor={pallet.backgroundColorPrimary}
        border={"1px solid lightgrey"}
        fontColor={pallet.textColorPrimary}
        fontSize={pallet.general.size12}
        icon={<PersonAddIcon />}
        title={"CADASTRAR"}
      />

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid
            style={{
              fontFamily: `${pallet.fontFamily}`,
              fontSize: `${pallet.general.size18}`,
              color: `${pallet.textColorSecondary}`,
              padding: "12px",
              marginTop: "7px",
            }}
          >
            CADASTRAR UNIDADE DE NEGÓCIO
          </Grid>
        </BootstrapDialogTitle>

        <DialogContent dividers style={{ padding: "0px" }}>
          <Grid
            container
            style={{
              fontFamily: pallet.fontFamily.general,
              padding: "25px 20px 20px 40px",
            }}
          >

            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <InputTextField
                type={"text"}
                value={nomeDepartamento}
                name="nomeDepartamento"
                onChange={handleChangeNome}
                id={"nome-departamento"}
                label={"NOME DA UNIDADE DE NEGÓCIO"}
              />
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={6}
              xs={6}
            >
              <InputTextField
                type={"text"}
                value={emailDepartamento}
                name="emailDepartamento"
                onChange={handleChangeEmail}
                id={"email-departamento"}
                label={"EMAIL DA UNIDADE DE NEGÓCIO"}
              />
            </Grid>
            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
                           <Typography className={classes.heading} style={{
                            color: `${pallet.color.secundary}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.general.size12}`,
                            fontWeight: pallet.general.weight_600,
                            alignItems: "center",
                            letterSpacing: "1px",
                            borderRadius: "2px",
                            textTransform: "uppercase",
                            marginLeft: `${isMobile ? "10px" : "0px"}`,
                            marginBottom: `${isMobile ? "10px" : "10px"}`,

                        }}>
                            RESPONSÁVEIS DA UNIDADE DE NEGÓCIO
                        </Typography>
                  
                                    <InputDropDownCheckBoxNestedListaUnica
                                        items={usuarios}
                                        onChange={handleSetResponsaveisList}
                                        label={"Responsaveis da Unidade negócio"}
                                        editar={true}
                                        // selectedItems={responsavelList}
                                    />
                      </Grid>
                 
                            

            <Grid
              style={{ marginTop: "20px", padding: "10px" }}
              item
              lg={12}
              xs={12}
            >
                                         <Typography className={classes.heading} style={{
                            color: `${pallet.color.secundary}`,
                            fontFamily: `${pallet.fontFamily.general}`,
                            fontSize: `${pallet.general.size12}`,
                            fontWeight: pallet.general.weight_600,
                            alignItems: "center",
                            letterSpacing: "1px",
                            borderRadius: "2px",
                            textTransform: "uppercase",
                            marginLeft: `${isMobile ? "10px" : "0px"}`,
                            marginBottom: `${isMobile ? "10px" : "10px"}`,

                        }}>
                            MEMBROS DA UNIDADE DE NEGÓCIO
                        </Typography>
                  
                                    <InputDropDownCheckBoxNestedListaUnica
                                        items={usuarios}
                                        onChange={handleSetUserList}
                                        label={"Membros do Unidade de negócio"}
                                        editar={true}
                                        // selectedItems={userList}
                                    />

            </Grid>



            <Grid
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "2px",
                marginLeft: "2px",
              }}
            >
              <ErrorMessage name="keyword" errors={error} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            padding: "30px",
            backgroundColor: `${pallet.backGroundPageColorPrimary}`,
          }}
        >
          <ButtonDialogConfirmation
            styles={{
              borderRadius: "6px 6px 0px 0px",
              marginRight: "20px",
              textTransform: "capitalize",
              color: `${pallet.general.color1}`,
            }}
            title={"CONFIRMAÇÃO"}
            title_button={"Cancelar"}
            content={"CANCELAR CADASTRO?"}
            message={"Esta ação não poderá ser desfeita."}
            confirmation_button={"Cancelar"}
            confirmationEvent={() => {
              handleClose();
            }}
          />
          <SolidButton
            onClick={postCitacao}
            onChange={props?.reloadList}
            color={`${pallet.textColorPrimary}`}
            backgroundColor={`${pallet.backgroundColorPrimary}`}
            fontSize={`${pallet.general.size14}`}
            title={"SALVAR"}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
