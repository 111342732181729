import React from "react";
import { useParams } from "react-router-dom";
import { Grid, Paper } from "@mui/material";

import Main from "../main/Main";

import ProposicaoConsultaHeader from './_components/consulta/ProposicaoConsultaHeader';
import { ProposicaoConsultaBotoes } from './_components/consulta/ProposicaoConsultaBotoes';
import { ProposicaoConsultaInicio } from './_components/consulta/ProposicaoConsultaInicio';
import { ProposicaoConsultaAutores } from './_components/consulta/ProposicaoConsultaAutores';
// import { ProposicaoConsultaWordCloud } from './_components/consulta/ProposicaoConsultaWordCloud';
import { ProposicaoConsultaInformacoes } from './_components/consulta/ProposicaoConsultaInformacoes';
import { ProposicaoConsultaAnaliseDeImpacto } from './_components/consulta/ProposicaoConsultaAnaliseDeImpacto';
import { ProposicaoConsultaClassificacaoDeTema } from './_components/consulta/ProposicaoConsultaClassificacaoDeTema';
import { ProposicaoPosicionamentoUnidadeNegocio } from "./_components/consulta/ProposicaoPosicionamentoUnidadeNegocio";
import ProposicaoCarouselTabs from './_components/consulta/ProposicaoCarouselTabs';

import useStyles from "../../components/layout/Styles";
import { IProposicao } from "../../models/Proposicao.Model";
import ProposicaoService from '../../services/ProposicaoService';
import { useAuthorization } from "../../contexts/AuthorizationContext";


export default function ProposicaoConsulta() {

    const { id } = useParams();
    const classes = useStyles();
    const { user } = useAuthorization();
    const [proposicao, setProposicao] = React.useState<IProposicao>();
    const [cloudWord, setCloudWord] = React.useState<Array<any>>([]);
    const [openaiProposicao, setOpenaiProposicao] = React.useState<IProposicao>();

    React.useEffect(() => {
        loadProposicao();
        loadOpenaiProposicao();
        loadProposicaoWordCloud();
    }, [id])

    const loadProposicao = () => {
        if (id)
            setTimeout(() => {
                ProposicaoService
                    .Get(Number(id))
                    .then(proposicao => {
                        setProposicao(proposicao)
                    });

            }, 1000);
    };


    const loadProposicaoWordCloud = () => {
        if (id)
            setTimeout(() => {
                ProposicaoService
                    .GetWordCloud(Number(id))
                    .then(cloudword => {
                        setCloudWord(cloudword.data.relevancia)
                    });

            }, 1000);
    };

    const loadOpenaiProposicao = () => {
        if (id)
            setTimeout(() => {
                ProposicaoService
                    .GetOpenaiProposicaoData(Number(id))
                    .then(proposicao => {
                        setOpenaiProposicao(proposicao)
                    });
            }, 1000);
    };
    return (
        <div className={classes.root}>
            <Main>
                <>
                    <Grid maxWidth={"100%"} container justifyContent="space-around">
                        <Paper style={{ borderRadius: "7px", width: "100%" }}>
                            <ProposicaoConsultaHeader />

                            {proposicao && (
                                <>
                                    <ProposicaoConsultaBotoes proposicao={proposicao} permissaoUsuario={user.id_permissao} />
                                    <ProposicaoConsultaInicio id_proposicao={Number(id)} proposicao={proposicao} proposicao_integrada={proposicao?.integrada} />
                                    <ProposicaoConsultaAutores proposicao={proposicao} permissaoUsuario={user.id_permissao} />
                                    {
                                        cloudWord ?
                                            null
                                            // <ProposicaoConsultaWordCloud proposicao={proposicao} cloudWord={cloudWord} permissaoUsuario={user.id_permissao} />
                                            :
                                            null
                                    }
                                    <ProposicaoConsultaInformacoes proposicao={proposicao} ia={openaiProposicao} />
                                    {user.id_permissao !== 4 ?
                                        <>
                                            <ProposicaoConsultaAnaliseDeImpacto proposicao={proposicao} onChange={() => loadProposicao()} idPermissao={user.id_permissao} />
                                            <ProposicaoPosicionamentoUnidadeNegocio proposicao={proposicao} onChange={() => loadProposicao()} idPermissao={user.id_permissao} />
                                            <Grid style={{ marginBottom: "30px" }}>
                                                <ProposicaoConsultaClassificacaoDeTema proposicao={proposicao} ia={openaiProposicao} onChange={() => loadProposicao()} idPermissao={user.id_permissao} />
                                            </Grid>
                                        </> : null
                                    }
                                </>
                            )}
                        </Paper>

                        <Grid container style={{ paddingTop: "30px" }}>
                            <Grid item style={{ width: "100%" }}>
                                <ProposicaoCarouselTabs id_proposicao={Number(id)} proposicao_integrada={proposicao?.integrada} propDetail={proposicao} onChange={() => loadProposicao()} permissaoUsuario={user.id_permissao} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </Main>
        </div>
    );
}
