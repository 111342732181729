import React, { useCallback, useEffect, useState } from "react";
import Main from "../../../main/Main";
import useStyles from "../../../../components/layout/Styles";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { usePallet } from "../../../../contexts/PalletContext";
import { isMobile } from "react-device-detect";
import { IAcaoPreparatoria, IProposicao } from "../../../../models/Proposicao.Model";
import { useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ProposicaoAcaoPreparatoriaService from "../../../../services/ProposicaoAcaoPreparatoriaService"
import { IListInfo } from "../../../../models/IListInfo";
import PaginationSaveState from "../../../../components/PaginationSaveState";
import { Visibility } from "@material-ui/icons";
import EmptyErrorDoc from "../../../../components/EmptyErrorDoc";
import CircularLoading from "../../../../components/CircularLoading";
import ParlamentarMenuFiltroTipoDemanda from "../../../../components/ParlamentarMenuFiltroTipoDemanda";
import AccordionNovaAcaoPreparatoria from "./AccordionNovaAcaoPreparatoria"
import ProposicaoAcaoPreparatoriaLista from "./ProposicaoAcaoPreparatoriaLista"
import ProposicaoAcaoPreparatoriaEdicaoSchema from '../../models/ProposicaoAcaoPreparatoriaEdicaoSchema';
import SolidButton from "../../../../components/SolidButton";
import AnexosComponent, { EAnexoModulos } from "../../../../components/Anexos";
import { arraysEquals } from "../../../../utils/array.utils";
import SelectTextFieldA from "../../../../components/SelectTextFieldA";
import InputMultilineTextField from "../../../../components/InputMultilineTextField";
import DeadLineDate from "../../../../components/DeadLineDate";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ICheckBoxNestedItem, InputDropDownCheckBoxNested } from "../../../../components/CheckBoxNested";
import UsuarioService from "../../../../services/UsuarioService";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useAlert } from "../../../../contexts/AlertContext";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { ICheckBoxNestedItemPrep, InputDropDownCheckBoxNestedAcaoPrep } from "../../../../components/CheckBoxNestedAcaoPrep";

export interface IFileInfo {
    name: string,
    filename: string,
    size: number,
    new: boolean,
    file: any
}

export default function ProposicaoAcaoPreparatoria(props: {
    index?: number,
    activeTab?: number,
    id_proposicao?: number,
    proposicao: IProposicao,
    lista?: Array<any>,
    onChange?: any,
    permissaoUsuario?: number

}): JSX.Element {

    const classes = useStyles();
    const { id } = useParams();
    const [data, setData] = useState({});
    const { NewAlert } = useAlert();
    const navigate = useNavigate();
    const [acaoPreparatoria, setAcaoPreparatoria] = React.useState<IAcaoPreparatoria>()
    const { pallet } = usePallet();
    const [idAcaoPreparatoria, setIdAcaoPreparatoria] = useState(0);
    const [prazo, setPrazo] = useState(Date);
    const [responsavel, setResponsavel] = useState('');
    const [responsaveisList, setResponsaveisList] = useState([{ id: "", label: "", value: "" }]);
    const [objetivo, setObjetivo] = useState('');
    const [objetivoTotal, setObejetivoTotal] = useState<number>(0);
    const [lista, setLista] = useState([]);
    const [infoList, setInfoList] = useState<IListInfo>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filtro, setFiltro] = useState<string>('T');
    const [opfiltro, setOpfiltro] = useState("Tudo");

    const [departamento, setDepartamento] = useState<Array<ICheckBoxNestedItemPrep>>();
    const [userList, setUserList] = useState<Array<number>>([]);
    const [deptList, setDeptList] = useState<Array<number>>([]);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [idReferencia, setIdReferencia] = React.useState<number>();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<string>();
    const [expandedAcordion, setExpandedAcordion] = useState(false);
    const [dataPrazo, setDataPrazo] = useState('');
    const [loadingForm, setLoadingForm] = useState(false);
    const [departamentoII, setDepartamentoII] =  React.useState<Array<any>>([]);
    // const handleReferencia = () => { idReferencia ? setIdReferencia(undefined) : setIdReferencia(97); }
    const [resultado, setResultado] = useState('');
    const [statusBotaoSalvar, setStatusBotaoSalvar] = useState(false);
    
    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red' }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    const AccordionSum = styled(AccordionSummary)`
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;   
    padding-top: 10px;
    height: 64px;
    flex-flow: row-reverse;

    .MuiAccordionSummary-content {
        flex-direction: column;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }
`;

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [currentPage, filtro])

    React.useEffect(() => {
        UsuarioService.GetByDpto().then(async res => {
            const comboItens = res.map(departamento => {
                return {
                    id: departamento.id,
                    label: departamento.nome,
                    value:false,
                    items: departamento.usuarios.map(usuario => {
                        return {
                            id: usuario.id,
                            label: usuario.nome,
                            value: false
                        }
                    })

                }
            })
            setDepartamento(comboItens)

        })
        UsuarioService.GetList().then(async res => {
            const responsaveis = res?.map(usuario => {
                return {
                    id: usuario.id,
                    label: usuario.nome,
                    value: usuario.id,
                }
            })
            setResponsaveisList(responsaveis);
        })


        if (departamento?.length > 0) {
            for (var i = 0; i < departamento?.length; i++) {
                departamentoII.push({ id: departamento[i].id, valor: false })
            }
        }

    }, [expandedAcordion]);

    const getList = useCallback(async () => {
        setLoading(true);
        const f = [{ key: 'status', value: filtro }]

        if (currentPage && currentPage > 0)
            await ProposicaoAcaoPreparatoriaService.GetList(props.id_proposicao, f, currentPage, 8).then(async res => {
                if (res && res.success) {
                    setLista(res.lista);
                    setInfoList(res.listInfo);

                }
                setLoading(false);
            })
    }, [props.id_proposicao, currentPage, filtro])

    const descTipo = (value: string) => {
        switch (value) {
            case "T": return "Tudo";
            case "C": return "Concluída";
            case "A": return "Em andamento";
            default: return "Tudo";
        }
    }

    const setPage = (p: number) => setCurrentPage(p);


    const setFilter = (f: any) => {
        setOpfiltro(descTipo(f.target.value));
        setFiltro(f.target.value);
    }
    const handleChangeNovaAcaoPreparatoria = () => {
        getList();
    };

    const handleChangeAcordion =
        (ativo: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedAcordion(!ativo);
            setStatusBotaoSalvar(false)
        };

    const handlePrazoChange = (prazoData) => {
        setPrazo(prazoData)
    };

    const handleObjetivoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setObjetivo(event.target.value)
        setObejetivoTotal(event.target.value.length)
    };

    const handleResponsavelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResponsavel(event.target.value)
    };

    function handleAfterUpload() {
        setIdAcaoPreparatoria(undefined);
        setPrazo(Date)
        setResponsavel("")
        setObjetivo("")
        // setDepartamento([])

        NewAlert('success', 'Ação Preparatória criada com sucesso')
    }

    const salvarNovaAcaoPreparatoria = () => {
        setStatusBotaoSalvar(true)
        const data = {
            prazo: prazo ? new Date(prazo) : null,
            id_responsavel: responsavel,
            objetivo: objetivo,
            responsaveis_esforco: userList,
            departamentos:departamentoII.filter(i => i.valor)
        }

        setError(() => [])
        // data && ProposicaoAcaoPreparatoriaEdicaoSchema
        //     .validate(data, { abortEarly: false })
        //     .catch(err => setError(() => err.errors));

        setData(data)

        ProposicaoAcaoPreparatoriaEdicaoSchema.validate(data).catch(function (err) {
        });
        // if (ProposicaoAcaoPreparatoriaEdicaoSchema.isValidSync(data)) {
            ProposicaoAcaoPreparatoriaService.Create(data, props?.id_proposicao).then(res => {
                if (res && res > 0) {
                    setIdAcaoPreparatoria(res)
                    setLoading(true);
                    setLoadingForm(true);
                    setTimeout(() => {
                        setIdReferencia(undefined);
                        setLoadingForm(false);
                        setLoading(false);
                    }, 100);
                    setExpandedAcordion(false)
                    setUserList([])
                    setDeptList([])
                    setDepartamento([])
                    
                    handleAfterUpload()
                    getList()
                    props?.onChange()

                } else {
                    NewAlert('error', 'Verifique os campos e tente outra vez');
                    setDepartamentoII([])
                }
                setDepartamentoII([])
            })
        // }
    }

    const handleSetUserList = React.useCallback((data) => {
        if (!arraysEquals(userList, data.selected)) {
            setDepartamento(() => [...data.list])
            setUserList(data.selected)
            
        }
    }, [userList])


    const handleSetDeptList = (id: number,valor:boolean ) => {
        departamentoII.map((a) => {
            if (a.id === id ) {
                a.valor = valor;
            };
        });
        // if()
        // departamentoII.push({id: id,valor:valor})
        
    };

    return (
        <>
            <div
            // style={{ minHeight: '800px' }}
            >
                {loadingForm ? (
                    <CircularLoading size={80} top={"50%"} left={"50%"} zIndex={2} />
                ) : props.permissaoUsuario != 4 ? (

                    <Grid
                        style={{
                            paddingLeft: `${isMobile ? "0px" : "30px"}`,
                            paddingRight: `${isMobile ? "0px" : "30px"}`,
                            paddingBottom: `${isMobile ? "20px" : "45px"}`,
                        }}
                    >
                        <Accordion
                            // expanded={expandedAcordion ? false : false}
                            onChange={handleChangeAcordion(expandedAcordion)}
                            disabled={false} elevation={0} style=
                            {{
                                border: "1px solid #EAEAEA",
                            }
                            }
                        >
                            <AccordionSum
                                expandIcon={<ArrowDropDownIcon
                                    style={{
                                        color: `${pallet.general.color3}`,
                                        fontSize: `${pallet.general.size16}`,

                                    }}
                                />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{
                                    backgroundColor: `${pallet.color.primary.background}`,
                                    borderRadius: "5px",

                                }}
                            >
                                <Typography className={classes.heading} style={{
                                    color: `${pallet.color.primary.font}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontWeight: pallet.fontWeight.subtitle,
                                    alignItems: "center",
                                    letterSpacing: "1px",
                                    marginLeft: "10px",
                                }}>
                                    NOVA AÇÃO PREPARATÓRIA
                                </Typography>
                            </AccordionSum>
                            <AccordionDetails style={{
                                fontFamily: `${pallet.fontFamily.general}`
                            }}>

                                <Grid >
                                    <Grid container xl={12} lg={12} xs={12} style={{ paddingTop: "20px" }}>
                                        <Grid container spacing={3}
                                            style={{ paddingLeft: `${isMobile ? "0px" : "24px"}`, paddingRight: `${isMobile ? "0px" : "24px"}` }}
                                        >
                                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }} >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}
                                                    style={{
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        fontWeight: pallet.general.weight_600,
                                                        color: `${pallet.general.color1}`,
                                                    }}>
                                                    <DeadLineDate
                                                        onChange={handlePrazoChange}
                                                        label={"PRAZO"}
                                                        inputFormat={"dd/MM/yyyy"}
                                                        value={prazo}
                                                        id={"prazo-acao_preparatoria"}
                                                    // minDate={new Date}
                                                    />
                                                    <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                        <ErrorMessage name='prazo' errors={error} />
                                                    </Grid>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item lg={6} xs={12} style={{ marginBottom: `${isMobile ? "0px" : "5px"}` }}>

                                                <SelectTextFieldA
                                                    value={responsavel}
                                                    onChange={handleResponsavelChange}
                                                    options={responsaveisList}
                                                    label={"RESPONSÁVEL PELA AÇÃO PREPARATÓRIA"}
                                                    id={"responsavel-acao_preparatoria"}
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='id_responsavel' errors={error} />
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                {/* <ErrorMessage name='apelido' errors={error} /> */}
                                            </Grid>
                                            <Grid item lg={12} xs={12}>
                                            <Grid  style= {{
                                                fontFamily: `${pallet.general.fontFamily}`,
                                                fontSize: `${pallet.general.size12}`,
                                                fontWeight: 'bolder',
                                                marginBottom:'15px'
                                                    }}>
                                                {"TOTAL DE CARACTERES DO OBJETIVO: " + objetivoTotal + "(MÁXIMO 10000 CARACTERES)"}
                                                </Grid>
                                                <InputMultilineTextField
                                                    type={"text"}
                                                    // focused={true}
                                                    placeholder={"Digite"}
                                                    maxRows={10}
                                                    maxLength={10000}
                                                    value={objetivo}
                                                    defaultValue={objetivo}
                                                    onChange={handleObjetivoChange}
                                                    id={"objetivo-acao_preparatoria"}
                                                    label="OBJETIVO"
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='objetivo' errors={error} />
                                                </Grid>
                                            </Grid>
                                            <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                {/* <ErrorMessage name='apelido' errors={error} /> */}
                                            </Grid>
                                            <Grid xl={2} lg={2} xs={12} container style={{
                                                paddingLeft: `${isMobile ? "25px" : "30px"}`
                                            }}
                                                justifyContent="flex-start"
                                                alignItems="center">
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid>
                                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "30px"}`, marginBottom: `${isMobile ? "15px" : "30px"}` }} />
                                    </Grid>

                                    <Typography className={classes.heading} style={{
                                        color: `${pallet.general.color1}`,
                                        fontFamily: `${pallet.fontFamily.general}`,
                                        fontSize: `${pallet.general.size12}`,
                                        fontWeight: pallet.general.weight_600,
                                        alignItems: "center",
                                        letterSpacing: "1px",
                                        borderRadius: "2px",
                                        textTransform: "uppercase",
                                        marginLeft: `${isMobile ? "10px" : "25px"}`,
                                        marginBottom: `${isMobile ? "10px" : "10px"}`,

                                    }}>
                                        Unidade de negócio
                                    </Typography>
                                    <Grid item lg={12} xs={12}
                                        style={{
                                            paddingLeft: `${isMobile ? "0px" : "24px"}`,
                                            paddingRight: `${isMobile ? "0px" : "24px"}`
                                        }}
                                    >
                                        {departamento &&
                                            <>
                                                <InputDropDownCheckBoxNestedAcaoPrep
                                                    items={departamento}
                                                    onChange={handleSetUserList}
                                                    label={"Solicitacao de"}
                                                    selectedItems={userList}
                                                    deptList={handleSetDeptList}
                                                />
                                                <Grid style={{ color: 'red', fontSize: '10px', marginTop: "2px", marginLeft: "2px" }}>
                                                    <ErrorMessage name='responsaveis_esforco' errors={error} />
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <Grid>
                                        < Divider variant="fullWidth" style={{ paddingTop: `${isMobile ? "15px" : "40px"}` }} />
                                    </Grid>
                                    <Grid style={{
                                        paddingTop: `${isMobile ? "15px" : "40px"}`,
                                        paddingLeft: `${isMobile ? "0px" : "24px"}`,
                                        paddingRight: `${isMobile ? "0px" : "24px"}`,
                                    }}>
                                        <Grid xs={12} lg={12} style={{ marginBottom: `${isMobile ? "8px" : "0px"}` }}>
                                            <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.tertiary.font}`,

                                            }}>
                                                ANEXOS
                                            </Grid>
                                            <Grid style={{ marginTop: "10px" }}>
                                                <Grid className="container">

                                                    <AnexosComponent
                                                        label={''}
                                                        modulo={EAnexoModulos.ProposicaoAcaoPreparatoria}
                                                        id_referencia={idAcaoPreparatoria}
                                                        onAfterUpload={handleAfterUpload}
                                                        canEdit={true}
                                                        autosave={true}
                                                    />
                                                </Grid>

                                            </Grid>


                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                            <Grid>
                                < Divider variant="fullWidth" style={{ paddingTop: "30px" }} />
                            </Grid>
                            <Grid container alignItems="center" sx={{
                                backgroundColor: `${pallet.backGroundPageColorPrimary}`,
                                height: `${isMobile ? "50px" : "98px"}`,
                                borderRadius: "5px"
                            }}>
                                <Grid container item>

                                    <Grid item lg={12} xs={12}
                                        style=
                                        {{
                                            paddingRight: `${isMobile ? "0px" : "40px"}`,
                                            textAlignLast: "end",
                                            padding: `${isMobile ? "0px" : "10px 20px 10px 20px"}`,
                                        }}>
                                        <SolidButton
                                            onClick={salvarNovaAcaoPreparatoria}
                                            // disabled={lockSaveButton}
                                            // onChange={props?.reloadList}
                                            color={`${pallet.textColorPrimary}`}
                                            backgroundColor={`${pallet.backgroundColorPrimary}`}
                                            fontSize={`${pallet.general.size14}`}
                                            title={"SALVAR"}
                                            disabled={statusBotaoSalvar}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Accordion >
                    </Grid>
                )

                    :

                    null


                }
                {
                    !expandedAcordion && infoList?.totalNoFilter === 0 || !expandedAcordion && infoList?.totalNoFilter === 0

                        ?

                        <Grid>
                            <Grid
                                style={{
                                    color: `${pallet.textColorQuaternary}`,
                                    fontSize: `${pallet.fontSize.titleWidgets}`,
                                    fontFamily: `${pallet.fontFamily.general}`,
                                    fontWeight: 600,
                                    textAlign: "center",
                                    paddingBottom: "100px",
                                    marginTop: "50px",
                                }}
                            >
                                <EmptyErrorDoc title= {props.permissaoUsuario !== 4 ? "Comece criando uma nova ação preparatória" : "Para está proposição ainda não foi criada Ação Preparatória."}
                                    description={props.permissaoUsuario !== 4 ? "Você ainda não criou nenhuma ação preparatória para esta proposição." : "" }
                                    subdescription="" />
                            </Grid>
                        </Grid>

                        : infoList?.totalNoFilter > 0 ?

                            <Grid style={{ paddingBottom: `${isMobile ? "0px" : "30px"}`, marginTop: `${isMobile ? "0px" : "30px"}` }}>
                                <Grid item xl={12} lg={12} xs={12} >
                                    <Divider variant="fullWidth" style={{ margin: "30px 0px 0px 0px" }} />
                                    <Grid container item alignItems={`${isMobile ? "center" : "flex-start"}`} style={{
                                        paddingLeft: `${isMobile ? "0" : "30px"}`,
                                        paddingRight: `${isMobile ? "0" : "30px"}`,
                                        paddingTop: `${isMobile ? "10px" : "30px"}`,
                                        marginBottom: `${isMobile ? "-10px" : "10px"}`
                                    }} >
                                        {
                                            isMobile ?
                                   
                                        <Grid container item alignItems={`${isMobile ? "center" : "center"}`} xs={12}  >
                                            <Grid xs={6}   
                                                alignItems={`${isMobile ? "center" : "baseline"}`}
                                            >
                                                <Grid item style={{
                                                    marginRight: `${isMobile ? "8px" : "8px"}`,
                                                    paddingLeft: `${isMobile ? "0" : "20px"}`,
                                                    justifyContent:"center",
                                                    alignContent:"space-between",
                                                    display:"flex",
                                                    
                                                }}>
                                                    <Grid item style={{
                                                        fontSize: "18px",
                                                        color: `${pallet.textColorTertiary}`,
                                                        fontWeight: pallet.general.weight_600
                                                    }}>
                                                        {infoList?.totalItens}
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                    <Grid item style={{
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        textAlign: `${isMobile ? "center" : "justify"}`,
                                                        fontSize: "14px",
                                                        fontWeight: pallet.general.weight_600,
                                                        paddingTop: `${isMobile ? "0" : "15px"}`,
                                                        marginBottom: `${isMobile ? "0" : "20px"}`

                                                    }}>
                                                        {infoList?.totalItens > 1 ? "AÇÕES PREPARATÓRIAS" : "AÇÃO PREPARATÓRIA"}

                                                        {isMobile ? (<> <br /> [{opfiltro}] </>)
                                                            :
                                                            (<> &nbsp; [{opfiltro}]</>)}

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid xs={6} container item justifyContent="flex-end" >
                                                <Grid item>
                                                    {/* Aproveitando o Mesmo Componente de filtro utilizado em Demanda, os campos são iguais */}
                                                    <Grid style={{ paddingRight: `${isMobile ? "10px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}` }}>
                                                        <ParlamentarMenuFiltroTipoDemanda title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                            :

                                            <Grid container item alignItems={`${isMobile ? "center" : "center"}`} xs={12} >
                                            <Grid xs={6} container item justifyContent={`${isMobile ? "center" : "normal"}`}
                                                alignItems={`${isMobile ? "center" : "baseline"}`}
                                            >
                                                <Grid item style={{
                                                    marginRight: `${isMobile ? "8px" : "8px"}`,
                                                    paddingLeft: `${isMobile ? "0" : "20px"}`,

                                                }}>
                                                    <Grid item style={{
                                                        fontSize: "18px",
                                                        color: `${pallet.textColorTertiary}`,
                                                        fontWeight: pallet.general.weight_600
                                                    }}>
                                                        {infoList?.totalItens}
                                                    </Grid>
                                                </Grid>
                                                <Grid>
                                                    <Grid item style={{
                                                        fontFamily: `${pallet.fontFamily.general}`,
                                                        textAlign: `${isMobile ? "center" : "justify"}`,
                                                        fontSize: "14px",
                                                        fontWeight: pallet.general.weight_600,
                                                        paddingTop: `${isMobile ? "0" : "15px"}`,
                                                        marginBottom: `${isMobile ? "0" : "20px"}`

                                                    }}>
                                                        {infoList?.totalItens > 1 ? "AÇÕES PREPARATÓRIAS" : "AÇÃO PREPARATÓRIA"}

                                                        {isMobile ? (<> <br /> [{opfiltro}] </>)
                                                            :
                                                            (<> &nbsp; [{opfiltro}]</>)}

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid xs={6} container item justifyContent="flex-end" >
                                                <Grid item>
                                                    {/* Aproveitando o Mesmo Componente de filtro utilizado em Demanda, os campos são iguais */}
                                                    <Grid style={{ paddingRight: `${isMobile ? "10px" : "20px"}`, paddingTop: `${isMobile ? "10px" : "0px"}` }}>
                                                        <ParlamentarMenuFiltroTipoDemanda title={"Visualizar"} onChange={setFilter} icon={<Visibility />} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        }
                                        <Grid style={{
                                            width: "100%",
                                            paddingLeft: `${isMobile ? "20px" : "20px"}`,
                                            paddingRight: `${isMobile ? "20px" : "20px"}`,
                                            paddingTop: "20px",
                                            paddingBottom: `${isMobile ? "20px" : "40px"}`
                                        }}
                                        >
                                            <ProposicaoAcaoPreparatoriaLista
                                                lista={lista}
                                                proposicao={props.proposicao}
                                                onChange={handleChangeNovaAcaoPreparatoria}
                                                id_proposicao={props.id_proposicao}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container justifyContent="center" style={{ marginTop: "40px", marginBottom: "40px", paddingBottom: `${isMobile ? "30px" : "0px"}` }}>
                                    <PaginationSaveState
                                        name={'ProposicaoAcaoPreparatoria'}
                                        pages={infoList?.totalPages}
                                        page={currentPage}
                                        onChange={setPage}
                                    />
                                </Grid> */}

                                </Grid>
                            </Grid>
                            :
                            <Grid>
                            </Grid>
                }




            </div >
        </>

    )
}