import { format } from "date-fns";
import * as Yup from "yup";

const ProposicaoAcaoPreparatoriaEdicaoSchema = Yup.object({
    // prazo: Yup.date().required().min(format(new Date(), 'yyyy-MM-dd'), { name: "prazo", err: "Verifique se a data escolhida é válida" }),
    prazo: Yup.date().required({ name: "prazo", err: "Verifique se a data escolhida é válida" }),
    id_responsavel: Yup.string().required({ name: "id_responsavel", err: "Escolha um responsável pela ação preparatória" }),
    objetivo: Yup.string().required({ name: "objetivo", err: "Preencha o campo 'Objetivo' " }),
    responsaveis_esforco: Yup.array().of(Yup.number()).min(1, { name: "responsaveis_esforco", err: "Escolha responsáveis pelo esforço colaborativo" }),
    departamentos :Yup.array().of(Yup.number()).min(1, { name: "departamentos", err: "Escolha responsáveis pelo esforço colaborativo" }),
});

export default ProposicaoAcaoPreparatoriaEdicaoSchema;
