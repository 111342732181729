import React, { useEffect, useState } from 'react';
import Main from '../main/Main';
import { useParams } from 'react-router-dom';
import { Box, Checkbox, Grid } from '@mui/material';
import InputTextField from '../../components/InputTextField';
import InputTextFieldTags from '../../components/InputTextFieldTags';
import SelectTextFieldA from '../../components/SelectTextFieldA';
import ButtonDialogConfirmation from '../../components/ButtonDialogConfirmation';
import InputDatePicker from '../../components/InputDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useAlert } from '../../contexts/AlertContext';
import { usePallet } from '../../contexts/PalletContext';
import { useNavigate } from 'react-router-dom';
import useStyles from "../../components/layout/Styles";
import styled from "styled-components";
import uuid from 'uuid/dist/v1';
import { isMobile } from 'react-device-detect';
import TabelasService from '../../services/TabelasService';
import { IListFilterInputItem } from '../../components/filter/ListFilterItems';
import InputMultilineTextField from '../../components/InputMultilineTextField';
import LinkIcon from '@mui/icons-material/Link';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ParlamentarService from '../../services/ParlamentarService';
import { capitalizeName } from '../../utils/string.utils';
import AnexosComponent, { EAnexoModulos } from '../../components/Anexos';
import SolidButton from '../../components/SolidButton';
import ProposicaoService from '../../services/ProposicaoService';
import ProposicaoCadastroSchema from '../proposicao/models/ProposicaoCadastroSchema';
import CircularLoading from '../../components/CircularLoading';

const Title = styled(Grid)`
        color: ${(props => props.pallet.textColorSecondary)};
        font-weight: ${(props => props.pallet.general.weight_bold)};
        font-size: 14px;
        font-family: ${(props => props.pallet.general.fontFamily)};
        margin-bottom: 20px;
`;

const CheckboxStyled = styled(Checkbox)`
    .MuiSvgIcon-root {
        color: ${(props => props.pallet.textColorSecondary)};
    }
`;

const CheckBoxText = styled(Grid)`
        color: ${(props => props.checkText.textColorSecondary)};
        font-weight: ${(props => props.checkText.general.weight_100)};
        font-size: 14px;
        font-family: ${(props => props.checkText.general.fontFamily)};
        margin-bottom: 20px;
        padding-Top: 20px;
`;

const AutoresTag = styled.div`
    fieldset {
        border-color: lightgrey !important;
    }
`;

export default function ProposicaoCadastro() {
    const { pallet } = usePallet();
    const classes = useStyles();
    const { NewAlert } = useAlert();
    const navigate = useNavigate();

    const { idProposicao } = useParams() as any;
    const [idProposicaoEdit, setIdProposicaoEdit] = React.useState(idProposicao);
    const [integrada, setIntegrada] = React.useState<boolean>(false);

    const [anteprojeto, setAnteprojeto] = React.useState<boolean>(false);
    const [estado, setEstado] = React.useState<string>("");
    const [estadoItens, setEstadoItens] = React.useState<Array<IListFilterInputItem>>([]);
    const [casa, setCasa] = React.useState<string>("");
    const [casaItens, setCasaItens] = React.useState<Array<IListFilterInputItem>>([
        { key: '', label: '-- Selecione a Unidade da Federação -- ', value: '' },
    ]);
    const [esfera, setEsfera] = React.useState<string>("");
    const [tipo, setTipo] = React.useState<string>("");
    const [tipoItens, setTipoItens] = React.useState<Array<IListFilterInputItem>>([]);
    const [numero, setNumero] = React.useState<number>();
    const [dataApresentacao, setDataApresentacao] = React.useState<any>(Date);
    const [situacao, setSituacao] = React.useState<string>("");
    const situacaoItens: IListFilterInputItem[] = [
        { key: "1", label: '-- Selecione -- ', value: '' },
        { key: "87", label: "AGUARDANDO DESIGNAÇÃO DO RELATOR", value: "87" },
        { key: "42", label: "PRONTA PARA A PAUTA", value: "42" },
        { key: "48", label: "MATÉRIA EM TRAMITAÇÃO", value: "48" },
        { key: "64", label: "TRANSFORMADA EM NORMA JURÍDICA", value: "64" },
        { key: "77", label: "VETADA", value: "77" },
        { key: "106", label: "AGUARDANDO PARECER", value: "106" },
        { key: "8", label: "NÃO DEFINIDO", value: "0" },

    ];

    const [ementa, setEmenta] = React.useState<string>("");
    const [linkProposta, setLinkProposta] = React.useState<string>("");
    const [inteiroTeor, setInteiroTeor] = React.useState<string>("");
    const [inputAutor, setInputAutor] = React.useState<string>('');
    const [idCriado, setIdCriado] = React.useState<number>();
    const [idEditado, setIdEditado] = React.useState<number>();

    const [arrayAutor, setArrayAutor] = React.useState<any[]>([]);
    const [autorLista, setAutorLista] = React.useState([]);

    const [idReferencia, setIdReferencia] = React.useState<any>(idProposicao);
    const [error, setError] = useState<Array<{ name: string, err: string }>>([])
    const [onSave, setOnSave] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [seHandleUpload, setSeHandleUpload] = React.useState<boolean>(false);
    const [statusBotaoSalvar, setStatusBotaoSalvar] = useState(false);


    React.useEffect(() => {
        
        TabelasService.getTabelaEstados().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res?.map(item => data.push({ key: item.id, label: item.sigla, value: item.id }))
                setEstadoItens(() => [...data]);
            })

        TabelasService.getProposicaoTipos().then(
            res => {
                const data = []
                data.push({ key: '', label: '-- Selecione -- ', value: '' });
                res?.map(item => data.push({ key: item, label: item, value: item }))
                setTipoItens(() => [...data]);
            })


        if (idProposicaoEdit && idProposicaoEdit !== undefined) {

            ProposicaoService.Get(Number(idProposicaoEdit)).then(
                res => {
                    if (res.integrada) return NewAlert('error', 'Proposição não pode ser editada pois foi integrada automaticamente')
                    setIntegrada(res.integrada);
                    setCasa(String(res.id_casa));
                    setTipo(res.tipo);
                    setNumero(Number(res.numero));
                    setDataApresentacao(res.data_apresentacao);
                    setSituacao(String(res.situacao));
                    setEmenta(res.ementa);
                    setLinkProposta(res.uri);
                    setInteiroTeor(res.uri_teor);
                    setArrayAutor(res.autores.map((autor) => {
                        return {
                            ...autor,
                            id_autor: autor.id,
                            tagLabel: autor.sigla_partido ? autor.nome + " " + autor.sigla_partido : autor.nome
                        }
                    }));
                    setEstado(String(res.casa_id_estado));

                })
        }

    }, []);

    React.useEffect(() => {

        if (inputAutor && inputAutor.length && inputAutor.trim().length > 2) {
            ParlamentarService.GetPartialNameWithId(inputAutor, [{ key: 'esfera', value: esfera }])
                .then(async res => {
                    if (res) setAutorLista(res);
                });
        }
    }, [inputAutor]);

    React.useEffect(() => {

        if (estado) {
            TabelasService.getTabelaCasasByUf(estado)
                .then(async res => {
                    const data = []
                    data.push({ key: '', label: '-- Selecione -- ', value: '' });
                    res.map(item => data.push({ key: item.id, label: item.sigla + " - " + item.nome, value: item.id }))
                    setCasaItens(() => [...data]);
                });
        }
    }, [estado]);

    React.useEffect(() => {
        // handleOnAfterUpload();
        if (idCriado || idEditado) {
            setTimeout(() => {
                navigate(`/proposicao/${idCriado ? idCriado : idEditado}`);
            }, 2000)
            //setIdCriado(undefined)         
            //setIdProposicaoEdit(undefined)         
        }
    }, [seHandleUpload]);

    const ErrorMessage = (props: { name: string, errors: Array<{ name: string, err: string }> }) => {
        const itemError = props.errors.filter(err => err.name === props.name);
        return (
            <div style={{ color: 'red', fontSize: '11px', marginTop: "5px", }}>
                {itemError.length === 1 && itemError[0].err}
            </div>
        )
    }

    // --------------------- ARRAY DE PROFISSOES --------------------

    const handleAddAutor = (e, value?: [any]) => {
        if (!inputAutor && !value) return false
        const newValue = inputAutor;
        setInputAutor(''); //Limpar no começo pra não triggar outro evento
        if (value) return setArrayAutor([...arrayAutor, {
            id: uuid(),
            tagLabel: value[value.length - 1].label,
            value: value[value.length - 1].value,
            nome: value[value.length - 1].key,
        }]);
        // e.preventDefault()
        setArrayAutor([...arrayAutor, {
            id: uuid(),
            tagLabel: newValue,
        }])
    }

    const deleteTagAutor = (id) => {
        //faço uma copia do array antigo e add em um novo array
        const newArrayAutor = arrayAutor.slice(0);
        //Pego o index do array escolhido para exclusao da lista
        const index = newArrayAutor.findIndex(arrayAutor => arrayAutor.id === id);
        //removo a palavra selecionada apartir do seu index do array
        newArrayAutor.splice(index, 1);
        //add o novo array ao estado atual do componente
        setArrayAutor([...newArrayAutor]);
    };

    // --------------------- HANDLES ONCHANGE --------------------


    const handleAnteprojetoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAnteprojeto(event.target.checked)
    };
    const handleEstadoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEstado(event.target.value)
        setCasa('')

    };
    const handleCasaOnChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
        setCasa(event.target.value)

        if (Number(event.target.value) >= 31) {
            setEsfera('Municipal')
        }
        else setEsfera('Estadual')
    };

    const handleTipoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipo(event.target.value)
    };
    const handleNumeroOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 8)
            setNumero(Number(event.target.value))
    };
    const handleDataApresentacaoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataApresentacao(event)
    };
    const handleSituacaoOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSituacao(event.target.value)
    };
    const handleEmentaOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmenta(event.target.value)
    };
    const handleLinkPropostaOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLinkProposta(event.target.value)
    };
    const handleInteiroTeorOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInteiroTeor(event.target.value)
    };

    async function handleOnAfterUpload() {

        if (idReferencia) {
            setIdReferencia(undefined);
            //-->NewAlert('success', 'Proposicao criada com sucesso')
            setOnSave(false)

            // if (idCriado || idProposicaoEdit) {
            //     setTimeout(() => {
            //         navigate(`/proposicao/${idCriado ? idCriado : idProposicaoEdit}`);
            //         setIdCriado(undefined)         
            //         setIdProposicaoEdit(undefined)      
            //     }, 3000)               
            // }

            setSeHandleUpload(true);
        }

    }
    const saveProposicao = async (e) => {
        setStatusBotaoSalvar(true)
        setOnSave(true)
        setLoading(true)
        e.preventDefault();
        const data = {
            id_casa: casa,
            tipo: tipo,
            numero: numero,
            data_apresentacao: dataApresentacao ? new Date(dataApresentacao) : null,
            ementa: ementa,
            texto_ementa: ementa,
            uri: linkProposta,
            uri_teor: inteiroTeor,
            situacao: {
                id: situacao,
                descricao: situacaoItens.filter((sit) => sit.value === situacao)[0].label,
            },
            autores: arrayAutor.map((autor) => {
                return {
                    id: autor.id_autor ? autor.id_autor : null,
                    nome: autor.nome ? autor.nome : autor.tagLabel,
                    id_parlamentar: autor.value ? autor.value : null,
                }
            }),
        }

        setError(() => [])
        data && ProposicaoCadastroSchema
            .validate(data, { abortEarly: false })
            .catch(err => setError(() => err.errors));

        if (ProposicaoCadastroSchema.isValidSync(data)) {
            if (idProposicaoEdit) {
                await ProposicaoService.Update(idProposicaoEdit, data)
                    .then(res => {
                        if (res && res > 0) {
                            setIdReferencia(res)
                           
                            navigate("/proposicao")
                            NewAlert('success', 'Proposição editada com sucesso')
                            // setTimeout(() => {
                            //     navigate(`/proposicao/${idProposicaoEdit}`);
                            // }, 2000);                            
                            setIdEditado(res)
                        } else {
                            NewAlert('error', 'Erro ao editar, verifique os campos obrigatórios e salve novamente');
                        }
                    });

            } else {

                await ProposicaoService.Create(data)
                    .then(res => {
                        if (res) {
                            
                            setLoading(true)
                            setTimeout(() => {
                                setLoading(false)
                            }, 3000);
                            setIdReferencia(res)
                            navigate("/proposicao")
                            NewAlert('success', 'Proposição cadastrada com sucesso')
                            setIdCriado(res)
                            // while (!seHandleUpload) {
                            //     navigate(`/proposicao/${res}`);
                            // }
                            // setSeHandleUpload(false)
                            // setTimeout(() => {
                            //     navigate(`/proposicao/${res}`);
                            // }, 3000);
                        } else {
                            setLoading(false)
                            NewAlert('error', 'Erro ao cadastrar, verifique os campos obrigatórios e salve novamente');
                        }
                    });
            }
        }
        else {
            setLoading(false)
            NewAlert('error', 'Favor preencher os campos obrigatórios');
        }
    };

    const clearForm = () => {
        setCasa("");
        setTipo("");
        setNumero(undefined);
        setDataApresentacao("");
        setSituacao("");
        setEmenta("");
        setLinkProposta("");
        setInteiroTeor("");
        setArrayAutor([]);
        setEstado("");
        setIdProposicaoEdit(undefined);
        setIdCriado(undefined);
    }

    return (
        <>

                <div className={classes.root}>
                    <Main>
                        <Grid>
                            <Grid style={{
                                color: `${pallet.textColorSecondary}`,
                                fontWeight: pallet.general.weight_bold,
                                fontSize: "24px",
                                fontFamily: `${pallet.general.fontFamily}`,
                                marginTop: "30px"
                            }}>
                                {idProposicao ? "Editar" : "Cadastrar"} Proposição
                            </Grid>
                            <Grid style={{
                                backgroundColor: `${pallet.backGroundPageColorSecondary}`,
                                padding: `${isMobile ? "15px" : "40px"}`,
                                marginTop: "30px"
                            }}>
                                <Box component="form" style={{ marginTop: "10px" }}>

                                    <Grid xl={12} lg={12} xs={12} container style={{
                                        // paddingLeft: `${isMobile ? "25px" : "30px"}`
                                    }}
                                        justifyContent="flex-start"
                                        alignItems="center">
                                        <Title pallet={pallet}>
                                            DADOS DA PROPOSIÇÃO
                                        </Title>
                                    </Grid>
                                    {/* Esconder pois funcionalidade ainda não existe, em desenvolvimento */}
                                    {/* <Grid xl={12} lg={12} xs={12} container style={{
                                // paddingLeft: `${isMobile ? "25px" : "30px"}`
                            }}
                                justifyContent="flex-start"
                                alignItems="center">

                                <FormGroup>
                                    <FormControlLabel control={
                                        <CheckboxStyled
                                            pallet={pallet}
                                            id={"anteprojeto-proposicao"}
                                            onChange={handleAnteprojetoOnChange}
                                            checked={anteprojeto}
                                        />}
                                        label={
                                            <CheckBoxText checkText={pallet}>
                                                Anteprojeto de Lei
                                            </CheckBoxText>
                                        } />
                                </FormGroup>
                            </Grid> */}
                                </Box>
                                <Box component="form" style={{ marginTop: "30px" }}>
                                    <Grid container spacing={3} rowSpacing={5}>
                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                name={"estado"}
                                                value={estado}
                                                onChange={handleEstadoOnChange}
                                                options={estadoItens}
                                                label={"UNIDADES DA FEDERAÇÃO"}
                                                id={"estado-proposicao"}
                                                focused={true}
                                                placeholder={"Selecione"}
                                            />
                                            <ErrorMessage name='uf' errors={error} />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                name={"casa"}
                                                value={casa}
                                                onChange={handleCasaOnChange}
                                                options={casaItens}
                                                label={"CASA LEGISLATIVA"}
                                                id={"casa-proposicao"}
                                                focused={true}
                                                placeholder={"Selecione"}
                                            />
                                            <ErrorMessage name='casa' errors={error} />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                name={"tipo"}
                                                value={tipo}
                                                onChange={handleTipoOnChange}
                                                options={tipoItens}
                                                label={"TIPO DE DOCUMENTO"}
                                                id={"tipo-proposicao"}
                                                focused={true}
                                            />
                                            <ErrorMessage name='tipo' errors={error} />
                                        </Grid>
                                    </Grid>

                                </Box>
                                <Box component="form" style={{ marginTop: "40px" }}>
                                    <Grid container spacing={3} rowSpacing={5}>
                                        <Grid item lg={4} xs={12}>
                                            <InputTextField
                                                type={"number"}
                                                value={numero}
                                                onChange={handleNumeroOnChange}
                                                id={"numero-proposicao"}
                                                label={"NÚMERO"}
                                                focused={true}
                                                placeholder={"Digite"}
                                            />
                                            <ErrorMessage name='numero' errors={error} />
                                        </Grid>
                                        <Grid item lg={4} xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <InputDatePicker
                                                    onChange={handleDataApresentacaoOnChange}
                                                    label={"DATA DE APRESENTAÇÃO"}
                                                    inputFormat={"dd/MM/yyyy"}
                                                    value={dataApresentacao}
                                                    id={"dataApresentacao-proposicao"}
                                                    startAdornment={
                                                        <CalendarTodayIcon />
                                                    }
                                                />
                                            </LocalizationProvider>
                                            <ErrorMessage name='data' errors={error} />
                                        </Grid>

                                        <Grid item lg={4} xs={12}>
                                            <SelectTextFieldA
                                                name={"situacao"}
                                                value={situacao}
                                                onChange={handleSituacaoOnChange}
                                                options={situacaoItens}
                                                label={"SITUAÇÃO"}
                                                id={"situacao-proposicao"}
                                                focused={true}
                                                placeholder={"Selecione"}
                                            />
                                            <ErrorMessage name='situacao' errors={error} />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box component="form" style={{ marginTop: "40px" }}>
                                    <Grid container spacing={3} rowSpacing={5}>
                                        <Grid item lg={12} xs={12}>

                                            <InputMultilineTextField
                                                type={"text"}
                                                maxRows={6}
                                                minRows={4}
                                                value={ementa}
                                                onChange={handleEmentaOnChange}
                                                label="EMENTA"
                                                id={"ementa-proposicao"}
                                                focused={true}
                                                placeholder={"Digite o texto principal da proposição"}
                                            />
                                            <ErrorMessage name='ementa' errors={error} />
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"url"}
                                                value={linkProposta}
                                                onChange={handleLinkPropostaOnChange}
                                                id={"link-proposta-proposicao"}
                                                label={"LINK DA PROPOSTA"}
                                                focused={true}
                                                placeholder={'http://'}
                                                startAdornment={
                                                    <LinkIcon />
                                                }
                                            />
                                            <ErrorMessage name='link-proposta' errors={error} />
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <InputTextField
                                                type={"url"}
                                                value={inteiroTeor}
                                                onChange={handleInteiroTeorOnChange}
                                                id={"inteiro-teor-proposicao"}
                                                label={"INTEIRO TEOR"}
                                                focused={true}
                                                placeholder={'http://'}
                                                startAdornment={
                                                    <LinkIcon />
                                                }
                                            />
                                            <ErrorMessage name='inteiro-teor' errors={error} />
                                        </Grid>

                                        {/* Mult Input */}
                                        {casa ?
                                            <Grid item lg={12} xs={12}>
                                                <AutoresTag>
                                                    <InputTextFieldTags
                                                        onDelete={(id) => deleteTagAutor(id)}
                                                        onChange={(e) => { if (e && e.type == "change") setInputAutor(capitalizeName(e.target.value.trim())) }}
                                                        onSelect={(e, value?) => { if (e.target.value !== "" && value[value.length - 1].label) handleAddAutor(e, value) }}
                                                        arrayItems={arrayAutor}
                                                        options={autorLista}
                                                        onKeyUp={(e) => { if (e.which === 13) { handleAddAutor(e) } }}
                                                        // onBlur={(e) => if (e.target.value) handleAddAutor(e)}
                                                        label={"AUTORES"}
                                                        type={'text'}
                                                        focused={true}
                                                        placeholder={"Busque por autor ou insira um novo"}
                                                    />
                                                </AutoresTag>
                                                <ErrorMessage name='autores' errors={error} />
                                            </Grid>
                                            : null
                                        }

                                    </Grid>
                                </Box>

                                <Box component="form" style={{ marginTop: "40px" }}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={12} xs={12}>
                                            <Grid style={{
                                                fontWeight: pallet.fontWeight.subtitle,
                                                fontSize: `${pallet.general.size12}`,
                                                color: `${pallet.color.tertiary.font}`,

                                            }}>
                                                ANEXOS
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={12} xs={12}>
                                            <Grid className="container">
                                                <AnexosComponent
                                                    label=''
                                                    modulo={EAnexoModulos.AnaliseDeImpacto}
                                                    id_referencia={idReferencia}
                                                    save={onSave}
                                                    canEdit={true}
                                                    autosave={idProposicaoEdit ? false : true}
                                                    onAfterUpload={handleOnAfterUpload}
                                                />

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {/* {idProposicaoEdit && (
                            <Box component="form" style={{ marginTop: "10px" }}>

                                <Grid xl={12} lg={12} xs={12} container style={{
                                    // paddingLeft: `${isMobile ? "25px" : "30px"}`
                                }}
                                    justifyContent="flex-start"
                                    alignItems="center">
                                    <Title title={pallet}>
                                        PROPOSIÇÃO APENSADA À
                                    </Title>
                                </Grid>

                                <Grid item lg={12} xs={12}>
                                    <AutoresTag>
                                        <InputTextFieldTags
                                                onDelete={(id) => deleteTagAutor(id)}
                                                onChange={(e) => { if (e && e.type == "change") setInputAutor(capitalizeName(e.target.value.trim())) }}
                                                onSelect={(e, value?) => { if (e.target.value !== "" && value[value.length - 1].label) handleAddAutor(e, value) }}
                                                arrayItems={arrayAutor}
                                                options={autorLista}
                                                onKeyUp={(e) => { if (e.which === 13) { handleAddAutor(e) } }}
                                                // onBlur={(e) => if (e.target.value) handleAddAutor(e)}
                                                label={"AUTORES"}
                                                type={'text'}
                                                focused={true}
                                                placeholder={"Busque por autor ou insira um novo"}
                                            />
                                    </AutoresTag>
                                </Grid>
                            </Box>
                        )} */}
                            </Grid>
                            <Grid container alignItems="center" justifyContent="end" style={{ backgroundColor: `${pallet.backgroundColorTertiary}`, border: "1px solid #EAEAEA", padding: "35px 20px 35px 50px" }}>
                               
                            {loading ?
                

                                    < CircularLoading
                                        size={80}
                                        top={"50%"}
                                        left={"50%"}
                                        zIndex={100}
                                    />

                                    :

                                    null

                                }

                               
                               
                                <Grid item style={{ marginRight: "20px" }}>
                                    <ButtonDialogConfirmation
                                        styles={{
                                            borderRadius: "6px 6px 0px 0px",
                                            // marginRight: "20px",
                                            textTransform: "capitalize",
                                            color: `${pallet.general.color1}`,
                                            lineHeight: "18px",
                                            fontSize: `${pallet.general.size14}`,

                                        }}
                                        lastPath={idProposicao ? `/proposicao/${idProposicao}` : "/proposicao"}
                                        title={"CONFIRMAÇÃO"}
                                        title_button={"Cancelar"}
                                        confirmation_button={"SAIR"}
                                        content={"SAIR SEM SALVAR?"}
                                        message={"As informações preenchidas serão perdidas."}
                                    />
                                </Grid>
                                <Grid item>
                                    <SolidButton
                                        onClick={saveProposicao}
                                        color={`${pallet.textColorPrimary}`}
                                        backgroundColor={`${pallet.backgroundColorPrimary}`}
                                        fontSize={`${pallet.general.size14}`}
                                        title={"SALVAR"}
                                        disabled={statusBotaoSalvar}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Main >
                </div >


            
        </>

    );
}